import React from 'react'
import Enumerable from 'linq';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {astVsModelData} from './cstmRptMain';
import { useState, useEffect, useRef } from 'react';
import {ResponsiveContainer} from 'recharts';
const ModelAllocationWidget = () => {
  // const assetVsColorModel = astVsModelData.ocAssetColorModel;
 
  // const assetVsModelFinalData = Enumerable.from(astVsModelData.t1)
  //   .join(
  //     assetVsColorModel,
  //     pk => pk.groupId,
  //     fk => fk.mjrAstTypeId,
  //     (left, right) => ({ ...left, ...right })
  //   )
  //   .toArray();
    //setAlldata(assetVsModelFinalData);
  const [allData, setAlldata] = useState(Enumerable.from(astVsModelData).where(w => w.prtfolio >= 0)
    .toArray());
    const labelContentAccVMdl = (props) => {

        let formatedNumber = Number(props.dataItem.mdlWegh).toLocaleString(undefined, {
          style: "percent",
          minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.mdlWegh.toFixed(2)}%`;
      };
  return (<>
  <h5 className='text-fitek p-1'>Model Allocation



</h5>      
    <ResponsiveContainer className='page-break'>
    {allData.length===0?<div>No Data Found.</div>:
        <Chart style={{ height: "440px" }}>
                    {/* <ChartTitle text="Major Asset Chart" /> */}
                    <ChartLegend position="bottom" />

                    <ChartSeries>
                      <ChartSeriesItem
                        type="pie"
                        data={Enumerable.from(allData).where(w => w.mdl > 0)
                          .toArray()}
                        field="mdlWegh"
                        categoryField="descption"
                        colorField='chartColorCOde'
                        autoFit={true}
                        labels={{
                          visible: true,
                          content: labelContentAccVMdl,
                        }}
                      />
                    </ChartSeries>
                  </Chart>}
      </ResponsiveContainer></>
  )
}

export default ModelAllocationWidget
