import React from 'react'
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { useState, useEffect, useRef } from 'react';
import { process } from '@progress/kendo-data-query';
import Enumerable from 'linq';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import {astVsModelData} from './cstmRptMain';
import {ResponsiveContainer} from 'recharts';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
const AssetAllocVsModelGridWidget = () => {
    
    console.log(astVsModelData);
    const assetVsColorModel = astVsModelData.ocAssetColorModel;
    var data= astVsModelData
    const assetVsModelFinalData = Enumerable.from(astVsModelData)
      .join(
        assetVsColorModel,
        pk => pk.groupId,
        fk => fk.mjrAstTypeId,
        (left, right) => ({ ...left, ...right })
      )
      .toArray();
  
    const [allData, setAlldata] = useState(astVsModelData);
    //setAlldata(assetVsModelFinalData);
    const initialDataState = {};
    let _pdfExport;
    const [dataState, setDataState] = React.useState();
    const [resultState, setResultState] = React.useState(
      process(allData, initialDataState)
    );
    const setWidth = (minWidth) => {
        let width = minWidth;
        return width;
    };
    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
      };

  const totalSum = (props) => {
    const field = props.field || "";
    const total = allData.reduce((acc, current) => acc + current[field], 0);
    return (
        <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {formatNumber(total, "##,#.00")}
        </td>
    );
};

  let defaultColumns = [
    {
        title: 'Descption',
        field: 'descption',
        minWidth: "auto",
        show: true,
        filter: 'text'
    },
    {
        title: 'Portfolio($)',
        field: 'prtfolio',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        headerClassName: 'rightHeader',
       // footerCell:totalSum
    },
    {
        title: '% Portfolio Weighting',
        field: 'prtfolioWeigh',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        
        headerClassName: 'rightHeader'
    },
    {
        title: 'Model($)',
        field: 'mdl',
        minWidth: "auto",
       
        className:'rightHeader',
        show: true,
        filter: 'numeric',
        headerClassName: 'rightHeader'
    },
    {
        title: '% Model Weighting',
        field: 'mdlWegh',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        headerClassName: 'rightHeader'
    },
    {
        title: '% Variance to Model',
        field: 'varitoMdl',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        headerClassName: 'rightHeader'
    },
    {
        title: '$ Variance to Model',
        field: 'varitoMdlVal',
        minWidth: 150,
        show: true,
        filter: 'numeric',
        headerClassName: 'rightHeader'
    },
  ];
  
 
  const GridColumns = defaultColumns;

  GridColumns[1].footerCell=totalSum ;

  GridColumns[2].footerCell=totalSum ;
  
  GridColumns[3].footerCell=totalSum ;
 
  GridColumns[4].footerCell=totalSum ;
  
  GridColumns[5].footerCell=totalSum ;

 //GridColumns[6].footerCell=totalSum ;
  
  const [stateColumns, setStateColumns] = useState(GridColumns);
  

  const cellRender = (tdElement, cellProps) => {
   
    if (cellProps.rowType === "data") {           
       
        if (cellProps.field === "prtfolio") {
  
            return (
                <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                    {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                </td>
            );
        }
        if (cellProps.field === "prtfolioWeigh") {
  
            return (
                <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                    {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                </td>
            );
        }
        if (cellProps.field === "mdl") {
  
            return (
                <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                    {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                </td>
            );
        }
        if (cellProps.field === "mdlWegh") {
  
            return (
                <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                    {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                </td>
            );
        }
        if (cellProps.field === "varitoMdl") {
  
            return (
                <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                    {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                </td>
            );
        }
        if (cellProps.field === "varitoMdlVal") {
  
          return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                  {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
          );
      }
    }
    return tdElement;
  };

  const onDataStateChange = React.useCallback((e) => {

    setDataState(e.dataState);
    setResultState(process(allData, e.dataState));
  }, []);



  const grid = (
    <Grid style={{ height: "auto" }}
//id='tileGrid'
    className='page-break'
      data={resultState}
      resizable={true}
      //reorderable={true}
      //sortable={true}
      groupable={{
        enabled:false,
        footer: 'visible',
      }}
      
      
      onDataStateChange={onDataStateChange}
      {...dataState}
      cellRender={cellRender}

    >


    {stateColumns.map(
                (column, idx) =>
                    column.show && (
                        <Column
                            width={setWidth(column.minWidth)}
                            key={idx}
                            field={column.field}
                            title={column.title}
                            headerClassName={column.headerClassName}
                            footerCell={column.footerCell}
                             columnMenu={(props) => (
                        <CustomColumnMenuNoGrp
                          {...props}
                          columns={stateColumns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                          
                        />
                    )
            )}
    </Grid>
  );
  return (
    <div><ResponsiveContainer>

<div><h5 className='text-fitek p-1'>Asset Allocation VS Model</h5>
    {grid}
</div>
    </ResponsiveContainer>
    </div>
  )
}

export default AssetAllocVsModelGridWidget
