import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useState, useEffect, useRef } from 'react';
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from '@progress/kendo-react-data-tools';
import { ColumnMenu } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ResponsiveContainer } from "recharts";
import {portfoliholdingsDatavar} from './cstmRptMain';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
// const aggregates = [
//     {
//       field: "market",
//       aggregate: "sum",
//     }
  
//   ];
const aggregates = [

  {
    field: "totMarket",
    aggregate: "sum",
  },
  {
    field: "income",
    aggregate: "sum",
  },
  {
    field: "txcstAmt",
    aggregate: "sum"
  },
  
  {
    field: "gainLoss",
    aggregate: "sum"
  },
  {
    field: "yield",
    aggregate: "sum"
  }


];
  const PortfolioHoldingWidget= () => {
    
    const totalSum = (props) => {
      const field = props.field || "";
      const total = portfolioHoldingsdata.reduce((acc, current) => acc + current[field], 0).toFixed(2);;
      return (
        <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
          {formatNumber(total, '##,#.00')}
        </td>
      );
    };
    const totalSumYield = (props) => {
      
      var totalIncome=0, totalMarket=0, totYield=0;
      for(var i=0; i<portfolioHoldingsdata.length; i++){
        totalIncome = totalIncome + portfolioHoldingsdata[i].income;
        totalMarket = totalMarket + portfolioHoldingsdata[i].totMarket;
      }
      if(totalMarket==0){
        totYield = 0;
      }
      else{
        totYield = (totalIncome*100)/totalMarket;
      }
      
      return (
        <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
          {formatNumber(totYield, '##,#.00')}
        </td>
      );
    };
    const processWithGroups = (data, dataState) => {
      const groups = dataState.group;
    
      if (groups) {
        groups.map((group) => (group.aggregates = aggregates));
      }
    
      dataState.group = groups;
      const newDataState = process(data, dataState);
      setGroupIds({
        data: newDataState.data,
        group: dataState.group,
      });
      return newDataState;
    };
    //const [portfolioHoldingsdata, setPortfolioHoldingsdata] = useState(JSON.parse(global.localStorage.getItem("portfoliHoldingData")));
    console.log({portfoliholdingsDatavar});
    const [portfolioHoldingsdata, setPortfolioHoldingsdata] = useState(portfoliholdingsDatavar);
    let defaultColumns = [
      // {
      //   title: 'Inv. Objective',
      //   field: 'invstmntObj',
      //   minWidth: 150,
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      // {
      //   title: 'Acct Type',
      //   field: 'accountType',
      //   minWidth: 150,
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      // {
      //   title: 'Account#',
      //   field: 'account',
      //   minWidth: 150,
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      // {
      //   title: 'Major Asset',
      //   field: 'majorAssetType',
      //   minWidth: 150,
      //   show: true,
      //   filter: 'text',
      //   locked: true,
      // },
      {
        title: 'Ticker/Cusip',
        field: 'tckerCusip',
        minWidth: "auto",
        show: true,
        filter: 'text',
        locked: false,
      },
      {
        title: 'Description',
        field: 'assetShrtNm',
        minWidth: 'auto',
        show: true,
        filter: 'text',
        locked: false,
      },
      {
        title: 'Unit',
        field: 'units',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Cost($)',
        field: 'txcstAmt',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Price($)',
        field: 'price',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Market($)',
        field: 'totMarket',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        //footerCell: totalSum,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Gain/Loss($)',
        field: 'gainLoss',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Income($)',
        field: 'income',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      {
        title: 'Yield(%)',
        field: 'yield',
        minWidth: 'auto',
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
      // {
      //   title: 'Principal($)',
      //   field: 'p1CashBlncAmt',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'Inv. Income($)',
      //   field: 'p2P3CashBlncAmt',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'UnEx. Cash($)',
      //   field: 'unExecCashAmt',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'Trade Cash($)',
      //   field: 'tradeCash',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      // {
      //   title: 'Excld Cash($)',
      //   field: 'excldCashAmt',
      //   minWidth: 130,
      //   show: true,
      //   filter: 'numeric',
      //   locked: false,
      //   headerClassName: 'rightHeader',
      // },
      {
        title: 'Asset Class(%)',
        field: 'equityPercent',
        minWidth: "auto",
        show: true,
        filter: 'numeric',
        locked: false,
        headerClassName: 'rightHeader',
      },
    ];
    defaultColumns[3].footerCell=totalSum ;
    defaultColumns[5].footerCell=totalSum ;
    defaultColumns[6].footerCell=totalSum ;
    defaultColumns[7].footerCell=totalSum ;
  
    defaultColumns[8].footerCell=totalSumYield ;
    const [stateColumns, setStateColumns] = useState(defaultColumns);
    
    const createDataState = (dataState) => {
        return {
          result: process(portfolioHoldingsdata.slice(0), dataState),
          dataState: dataState,
        };
      };
      let initialState = createDataState({
        take: Number.MAX_VALUE,
        skip: 0,
        group: [{field: 'majorAssetType'}]
      });
      let initialStateExport = createDataState({
        take: Number.MAX_VALUE,
        skip: 0,
        group: [{field: 'majorAssetType'}]
      });
      const [result, setResult] = React.useState(
        processWithGroups(portfolioHoldingsdata, initialState.dataState)
      );
      // const [result, setResult] = React.useState(initialState.result);
      const [dataState, setDataState] = React.useState(initialState.dataState);

   
      const [sort, setSort] = React.useState([]);
      const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
      };
      const expandChange = (event) => {
        const isExpanded =
          event.dataItem.expanded === undefined
            ? event.dataItem.aggregates
            : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
      };
    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const NumberCellSixDecimal = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.000000")}
            </td>
        )
    }
    const getCells = (columns, cellProps) => {
      let cells = [];
      columns.forEach((column) => {
        if (column.aggregate) {
          cells.push(
            <td style={{ textAlign: "right" }}>
              {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
            </td>
          );
        } else {
          cells.push(<td>&nbsp;</td>);
        }
      });
      return cells;
    };
    const cellRender = (tdElement, cellProps) => {
      
      // if (
      //    cellProps.rowType === 'groupHeader' &&
      //    tdElement &&
      //    tdElement.props.role != 'presentation'
      //  ) {
      //    //IMPORTANT - You need to add collection with the columns and their field name
      //    //you can define the Grid columns outside of the Grid and reuse them here.
      //    const columns = [
      //      // { field: 'invstmntObj' },
      //      // { field: 'accountType' },
      //      // { field: 'account'},
      //      { field: 'majorAssetType'},
      //      { field: 'tckerCusip'},
      //      { field: 'assetShrtNm'},
      //      { field: 'units'},
      //      { field: 'txcstAmt'},
      //      { field: 'price' },
      //      { field: 'totMarket'},
      //      { field: 'gainLoss' },
      //      { field: 'income' },
      //      { field: 'yield' },
      //      //{ field: 'p1CashBlncAmt' },
      //      //{ field: 'p2P3CashBlncAmt' },
      //      { field: 'unExecCashAmt' },
      //      //{ field: 'tradeCash'},
      //      //{ field: 'excldCashAmt'},
      //      { field: 'equityPercent'},
      //    ];
      //    
      //    return (
      //      <>
      //        <td
      //          {...tdElement.props}
      //          colSpan={tdElement.props.colSpan - columns.length}
      //        ></td>
      //        {getCells(columns, cellProps)}
      //      </>
      //    );
      //  }
       
       if (cellProps.rowType === 'groupFooter') {
   
   var grpYield=0;
         if (cellProps.field === "income") {
           
           localStorage.setItem("grpTotInc",cellProps.dataItem.aggregates.income.sum)
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem.aggregates.income.sum, "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "totMarket") {
           
           localStorage.setItem("grpTotMrkt",cellProps.dataItem.aggregates.totMarket.sum)
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem.aggregates.totMarket.sum, "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "gainLoss") {
           
           localStorage.setItem("grpTotMrkt",cellProps.dataItem.aggregates.totMarket.sum)
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem.aggregates.gainLoss.sum, "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "txcstAmt") {
          
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem.aggregates.txcstAmt.sum, "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "yield") {
   
   if(JSON.parse(localStorage.getItem("grpTotMrkt"))>0){
     //grpYield=(grpTotInc*100)/grpTotMrkt;
   grpYield=(JSON.parse(localStorage.getItem("grpTotInc"))*100)/JSON.parse(localStorage.getItem("grpTotMrkt"));
   }
   localStorage.setItem("grpTotInc",0);
   localStorage.setItem("grpTotMrkt",0);
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(grpYield, "##,#.00")}
             </td>
           );
   
         }
   
       }
   
       if (cellProps.rowType === "data") {
   
         if (cellProps.field === "totMarket") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "units") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "txcstAmt") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "price") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "gainLoss") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "income") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "yield") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "p1CashBlncAmt") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "p2P3CashBlncAmt") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "unExecCashAmt") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "tradeCash") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "excldCashAmt") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
         if (cellProps.field === "equityPercent") {
   
           return (
             <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
               {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
             </td>
           );
         }
       }
       return tdElement;
     };
   
      const setWidth = (minWidth) => {
        let width = minWidth;
        return width;
      };
      const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
      };
    return(
        
          <ResponsiveContainer >   
            <div>
            <h5 className='text-fitek p-1'>Portfolio Holdings</h5>
            <div >
            <p>Investment Objective : {portfoliholdingsDatavar[0].invstmntObj}</p>
            </div>
            <div className="mx-1 my-1 py-1">
            <Grid style={{ height: "300px" }}          
                data={result}
                onDataStateChange={dataStateChange}
                expandField="expanded"
                onExpandChange={expandChange}
                {...dataState}
                scrollable={"scrollable"}
                //pageable={true}
                //pageSize={10}
                //resizable={true}
                //reorderable={true}
                groupable={{
                footer: 'visible',
                enabled: false
              }}
                sortable={true}
                cellRender={cellRender}
          >
            {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      //locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                      //cell={NumberCell}
                      columnMenu={(props) => (
                        <CustomColumnMenuNoGrp
                          {...props}
                          columns={stateColumns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                    />
                  )
              )}
            {/* <Column field="branchName" menu={true} title="Branch" width="auto" /> */}
                {/* <Column field="invstmntObj" title="Inv. Objective" width="auto" /> */}
                {/* <Column field="accountType" menu={true} title="Acct. Type" width="auto" />
                <Column field="accountName" menu={true} title="Account#" width="auto" /> */}
                {/* <Column field="tckerCusip" title="Ticker/Cusip" width="auto" />
                <Column field="assetShrtNm" title="Description" width="auto" />
                <Column field="units" title="Units" width="auto" headerClassName='rightHeader'  cell={NumberCell}/>
                <Column field="txcstAmt" title="Cost($)" width="auto" headerClassName='rightHeader'  cell={NumberCell}/>
                <Column field="price" title="Price($)" width="auto" headerClassName='rightHeader'  cell={NumberCell}/>
                <Column field="totMarket" title="Market($)" width="auto" headerClassName='rightHeader' footerCell={totalSum}   cell={NumberCell}/>
                <Column field="gainLoss" title="Gain Loss($)" width="auto" headerClassName='rightHeader'  cell={NumberCell}/>
                <Column field="income" title="Income($)" width="auto" headerClassName='rightHeader' cell={NumberCell}/>    
                <Column field="yield" title="Yield(%)" width="auto" headerClassName='rightHeader'  cell={NumberCell}/> */}
                {/* <Column field="p1CashBlncAmt" title="Principal($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/>
                <Column field="p2P3CashBlncAmt" title="Inv. Income($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/>
                <Column field="unExecCashAmt" title="UnEx. Cash($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/>
                <Column field="tradeCash" title="Trade Cash($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/>
                <Column field="excldCashAmt" title="Excld Cash($)" headerClassName='rightHeader'  width="auto"  cell={NumberCell}/> */}
                {/* <Column field="equityPercent" title="Equity %" headerClassName='rightHeader'  width="auto"  cell={NumberCell} /> */}

          </Grid>
          </div>
          <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className="subheader text-end col-md-2">
          <p>Principal Cash : {formatNumber(portfoliholdingsDatavar[0].p1CashBlncAmt, '##,#.00')}</p>
          </div>
          <div className="subheader text-end col-md-3">
          <p>Income Cash : {formatNumber(portfoliholdingsDatavar[0].p2P3CashBlncAmt, '##,#.00')}</p>
          </div>
          <div className="subheader text-end col-md-3">
          <p>Trade Cash : {formatNumber(portfoliholdingsDatavar[0].tradeCash, '##,#.00')}</p>
          </div>
          <div className="subheader text-end col-md-3">
          <p>Exclude Cash Cash : {formatNumber(portfoliholdingsDatavar[0].excldCashAmt, '##,#.00')}</p>
          </div>
        </div>
          </div>   
          </ResponsiveContainer>          
      
                    

                
    )

}
  export default PortfolioHoldingWidget

