import React from "react";
import logoDemo from "../Images/Demo.png";
import logoZions from "../Images/Zions.png";
import logoPark from "../Images/Park.png";
import logoEquitable from "../Images/Equitable.png";
import logoRCM from "../Images/RCM.png";
import logoNBT from "../Images/NBT.png";
import logoVermont from "../Images/Vermont.png";
import logoBankPlus from "../Images/Bplus.png";
import logoHeartlad from "../Images/Heartland.png";
import logoNixonPeabody from "../Images/nixon.png";
import logoGreatPlains from "../Images/GPT.png";
import logoFirstBusiness from "../Images/FirstBusinessBank.jpg";
import logoCharlotte from "../Images/Charlotte.png";
import logoIowa from "../Images/Iowa.jpg";
import logoQuadcity from "../Images/QUAD.png";
import logoBOAA from "../Images/BOAA.png";
import logoFrost from "../Images/Frost.png";
import logoLedyard from "../Images/Ledyard.png";
import logoRiverView from "../Images/Riverview.png";

import logoNT from "../Images/NT.png";
import logoGreenLeaf from "../Images/green leaf.png";
import logowye from "../Images/wye.png";
import logoBB from "../Images/BB.png";
import logoindiana from "../Images/Indiana.png";
import logocorefirst from "../Images/corefirst.png";
const LogoPage = () => {
  const styleLogo = {
    width: "160px",
  };
  var logo;

  if (process.env.REACT_APP_BANK === "Zions") {
    logo = logoZions;
  } else if (process.env.REACT_APP_BANK === "CoreFirst") {
    logo = logocorefirst;
  } else if (process.env.REACT_APP_BANK === "NT") {
    logo = logoNT;
  } else if (process.env.REACT_APP_BANK === "GLT") {
    logo = logoGreenLeaf;
  } else if (process.env.REACT_APP_BANK === "WYE") {
    logo = logowye;
  } else if (process.env.REACT_APP_BANK === "BB") {
    logo = logoBB;
  } else if (process.env.REACT_APP_BANK === "IND") {
    logo = logoindiana;
  } else if (process.env.REACT_APP_BANK === "Ledyard") {
    logo = logoLedyard;
  } else if (process.env.REACT_APP_BANK === "Riverview") {
    logo = logoRiverView;
  } else if (process.env.REACT_APP_BANK === "Quadcity") {
    logo = logoQuadcity;
  } else if (process.env.REACT_APP_BANK === "Annarbor") {
    logo = logoBOAA;
  } else if (process.env.REACT_APP_BANK === "FrostBank") {
    logo = logoFrost;
  } else if (process.env.REACT_APP_BANK === "Park") {
    logo = logoPark;
  } else if (process.env.REACT_APP_BANK === "Equitable") {
    logo = logoEquitable;
  } else if (process.env.REACT_APP_BANK === "RCM") {
    logo = logoRCM;
  } else if (process.env.REACT_APP_BANK === "NBT") {
    logo = logoNBT;
  } else if (process.env.REACT_APP_BANK === "Vermont") {
    logo = logoVermont;
  } else if (process.env.REACT_APP_BANK === "BankPlus") {
    logo = logoBankPlus;
  } else if (process.env.REACT_APP_BANK === "Heartland") {
    logo = logoHeartlad;
  } else if (process.env.REACT_APP_BANK === "NixonPeabody") {
    logo = logoNixonPeabody;
  } else if (process.env.REACT_APP_BANK === "GreatPlains") {
    logo = logoGreatPlains;
  } else if (process.env.REACT_APP_BANK === "FirstBusiness") {
    logo = logoFirstBusiness;
  } else if (process.env.REACT_APP_BANK === "Iowa") {
    logo = logoIowa;
  } else if (process.env.REACT_APP_BANK === "Charlotte") {
    logo = logoCharlotte;
  } else {
    logo = logoDemo;
  }

  return (
    <div>
      <img style={styleLogo} src={logo}></img>
    </div>
  );
};

export default LogoPage;
