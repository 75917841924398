import React from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ColumnMenu } from "./columnMenu";
import { orderBy } from "@progress/kendo-data-query";
import Enumerable from 'linq';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import { fipoMaurityLadder } from './cstmRptMain';
import {ResponsiveContainer} from 'recharts';

const FipoMtrtyLadderWidget = () => {
    
    let selAcct = JSON.parse(localStorage.getItem('AcctSelected'));
    let table3 = [];
    table3 = Enumerable.from(fipoMaurityLadder).where(w => w.acctId === selAcct.acctId).toArray();
    const [sort, setSort] = React.useState([]);
    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    // .sort((a, b) => a.matIndx - b.matIndx
    const RightNameHeader = (props) => {
        return (
            <a className="k-link" style={{
                float: "right",
            }} onClick={props.onClick}>
                {/* <span className="k-icon k-i-cart" /> */}
                <span
                    style={{
                        // color: "#53d2fa",
                    }}
                >
                    {props.title}
                </span>
                {props.children}
            </a>
        );
    };
    const FormatLongNumber = ({ value }) => {

        if (value == 0) {
            return 0;
        }
        else {
            // for testing
            //value = Math.floor(Math.random()*1001);

            // hundreds
            if (value <= 999) {
                return value;
            }
            // thousands
            else if (value >= 1000 && value <= 999999) {
                return (value / 1000) + 'K';
            }
            // millions
            else if (value >= 1000000 && value <= 999999999) {
                return (value / 1000000) + 'M';
            }
            // billions
            else if (value >= 1000000000 && value <= 999999999999) {
                return (value / 1000000000) + 'B';
            }
            else
                return value;
        }
    }
    const chartDefaultV4Colors = [
        "#00876c",
        "#6e9b75",
        "#a5af90",
        "#cbc6b9",
        "#c9a47e",
        "#d07958",
    ];
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
    const labelContent = (e) => `${e.value.toFixed(2)}`;
    return (
        <div><h5 className='text-fitek p-1'>Maturity Ladder
    </h5>
        <ResponsiveContainer className='page-break'>
        <div className='row d-flex m-1 p-1'>
            <div className='col'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>

                            <div className='col'>

                                <Grid style={{ height: "500px" }}
                                    data={orderBy(table3, sort)}
                                    sortable={true}
                                    sort={sort}
                                    onSortChange={(e) => {
                                        setSort(e.sort);
                                    }}>

                                    <Column field="ratingDesc" menu={true} title="Description" width="150px" />
                                    <Column field="shares" menu={true} title="Par Value" width="150px" columnMenu={ColumnMenu} cell={NumberCell} headerCell={RightNameHeader} />
                                </Grid>
                            </div>

                            <div className='col'>

                                <Chart seriesColors={chartDefaultV4Colors} style={{ height: "500px" }}>
                                    {/* <ChartTitle text="Major Asset Chart" /> */}
                                    <ChartLegend position="bottom" />

                                    <ChartValueAxis>
                                        <ChartValueAxisItem
                                            // title={{
                                            //     text: "Percentage",
                                            // }}
                                            min={0}
                                            labels={{
                                                visible: true,

                                                // rotation: 85,
                                                //format: "d",
                                                content: FormatLongNumber

                                            }}
                                        />
                                    </ChartValueAxis>
                                    <ChartCategoryAxis>
                                        <ChartCategoryAxisItem
                                            labels={{
                                                visible: true,
                                                //rotation: 85,
                                                format: "d",
                                            }}
                                        //  categories={categoryAxis} 
                                        />
                                    </ChartCategoryAxis>
                                    {/*<ChartTooltip render={defaultTooltipRender} />*/}
                                    <ChartSeries>
                                        <ChartSeriesItem
                                            type="column"
                                            data={(table3.sort((a, b) => a.matIndx - b.matIndx))}
                                            field="shares"
                                            categoryField="ratingDesc"

                                        /*labels={{
                                            visible: false,
                                            content: labelContent,
                                        }}*/
                                        />
                                    </ChartSeries>
                                </Chart>
                            </div>
                        </div>
                        <div className='row d-flex pt-2 p-1'>
                            <div className='col'>
                                <div className='card'>
                                    <div className='card-header'>Average Yield</div>
                                    <div className='card-body fw-bold'>{table3.length === 0 ? "0.00" : table3[0].avgYield}</div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='card'>
                                    <div className='card-header'>Average Maturity</div>
                                    <div className='card-body fw-bold'>{table3.length === 0 ? "0.00" : table3[0].avgMaturity}</div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='card'>
                                    <div className='card-header'>Average YTM</div>
                                    <div className='card-body fw-bold'>{table3.length === 0 ? "0.00" : table3[0].avgYTM}</div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='card'>
                                    <div className='card-header'>Avg Duration (Maturity)</div>
                                    <div className='card-body fw-bold'>{table3.length === 0 ? "0.00" : table3[0].avgDuration}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </ResponsiveContainer></div>
    )
}
export default FipoMtrtyLadderWidget