import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {ResponsiveContainer} from 'recharts';
import {topHoldingDatavar} from './cstmRptMain';

const TopHoldingsWidget=()=>{
    const [topHoldingsdata, settopHoldingsdata] = useState(topHoldingDatavar);
    const menuWithExcelCheckOnly = (props) => { 
      const props1 = {...props};
      return (
        <div>
          <ColumnMenuCheckboxFilter
            {...props1}
            data={topHoldingDatavar}
          />
        </div>)
    }
    const createDataState = (dataState) => {
        return {
          result: process(topHoldingsdata.slice(0), dataState),
          dataState: dataState,
        };
      };
      let initialState = createDataState({
        take: 20,
        skip: 0,
      });
      const [result, setResult] = React.useState(initialState.result);
      const [dataState, setDataState] = React.useState(initialState.dataState);
      const [sort, setSort] = React.useState([]);
      const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
      };

    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const NumberCellSixDecimal = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.000000")}
            </td>
        )
    }

    
    
    return(
        <div><h5 className='text-fitek p-1'>Top Holdings</h5>
        <ResponsiveContainer>
                            
                                <Grid style={{ width: "100%", height: "250px" }}                               
                                    data={result}
                                    scrollable={"scrollable"}
                                    onDataStateChange={dataStateChange}
                                    {...dataState}
                                    sortable={true}
                                    resizable={true}
                                    reorderable={true}                               
                                >    
                                    <Column field="tickerCusipConcate" menu={true} title="Ticker/Cusip : Asset" width="auto" columnMenu={menuWithExcelCheckOnly}/>
                                    <Column field="shares" menu={true} title="Shares" width="auto" 
                                             cell={NumberCell} headerClassName='rightHeader' columnMenu={ColumnMenu} />
                                    <Column field="txcstAmt" menu={true} title="Total Cost($)" width="auto"
                                      cell={NumberCell} headerClassName='rightHeader' columnMenu={ColumnMenu}/>
    
                                    <Column field="market" menu={true} title="Market Value($)" width="auto" 
                                             cell={NumberCell} headerClassName='rightHeader' columnMenu={ColumnMenu} />
    
                                    <Column field="marketPercent" menu={true} title="Percentage(%)" width="auto" 
                                             cell={NumberCell} headerClassName='rightHeader' columnMenu={ColumnMenu} />
    
                                </Grid>
                                </ResponsiveContainer>
                            </div>
                        
    
                    
    )
    
}


export default TopHoldingsWidget