import React from 'react'
import { useState, useEffect, useRef } from 'react';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import { ResponsiveContainer } from 'recharts';
import { TimeWtdRtnRptChartDatavar } from './cstmRptMain';

const TimeWtdRtnRptChartWidget = () => {
    const [data, setData] = useState(TimeWtdRtnRptChartDatavar);
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
    const labelContent = (e) => `${e.value.toFixed(2)}`;
    return(<>
      <h5 className='text-fitek p-1'>Performance Returns Chart
</h5>
        <ResponsiveContainer className='page-break'>
          
          {data.length===0?<div>No Data Found.</div>:
            <Chart >
                   <ChartTitle text="Maturity Date Vs Call/Put Date" /> 
                    <ChartTitle text="Returns (%)" />
                    <ChartLegend position="top" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        labels={{
                          visible: true,
                          rotation: 85,
                          format: "d",
                        }}
                      //  categories={categoryAxis} 
                      />
                    </ChartCategoryAxis>
                    <ChartTooltip render={defaultTooltipRender} />
                    
                    <ChartSeries>
                    <ChartSeriesItem
                        name='3 Yrs'
                        type='column'
                        data={data}
                        categoryField="groupHeader"
                        field="threeYear"
                        aggregate='sum'
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                      <ChartSeriesItem
                        name='5 Yrs'
                        type='column'
                        data={data}
                        categoryField="groupHeader"
                        field="fiveYear"
                        aggregate='sum'
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                      <ChartSeriesItem
                        name='10 Yrs'
                        type="column"
                        data={data}
                        categoryField="groupHeader"
                        field="tenYear"
                        aggregate='sum'
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                      <ChartSeriesItem
                        name='Inception'
                        type='column'
                        data={data}
                        categoryField="groupHeader"
                        field="inception"
                        aggregate='sum'
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                    </ChartSeries>
                  </Chart>}
                 
        </ResponsiveContainer> </>
        
    )

}

export default TimeWtdRtnRptChartWidget;