import React from 'react'

const pageNotFound = () => {
  return (
    <div className='text-center' >
    <h3>404 page not found</h3>
    <p>We are sorry but the page you are looking for does not exist.</p>
  </div>
  )
}

export default pageNotFound
