import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useState, useEffect, useRef } from 'react';
import { ColumnMenu } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
  } from "@progress/kendo-react-charts";
import { ResponsiveContainer } from "recharts";
import {perfChartDatavar} from './cstmRptMain';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
// const aggregates = [
//     {
//       field: "market",
//       aggregate: "sum",
//     }
  
//   ];

  const AcctperfChartWidget= () => {
    
    const labelContent = (e) => `${e.value.toFixed(2)}`;
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
    return(
      <><h5 className='text-fitek p-1'>Performance Chart Of Major Asset
      </h5> 
                <ResponsiveContainer className='page-break'>    
                {perfChartDatavar.length===0?<>No Data Found.</>:
            <Chart style={{  }}>
              {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
              <ChartLegend position="top" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    visible: true,
                    //rotation: 85,
                    format: "d",
                  }}
                //  categories={categoryAxis} 
                />
              </ChartCategoryAxis>
              <ChartTooltip render={defaultTooltipRender} />
              <ChartSeries>
                <ChartSeriesItem
                  type="column"
                  name='Start Percent'
                  data={perfChartDatavar}
                  categoryField="assetType"
                  field="startPercent"
                  labels={{
                    visible: false,
                    content: labelContent,
                  }}
                />
                <ChartSeriesItem
                  type="column"
                  name='End Percent'
                  data={perfChartDatavar}
                  categoryField="assetType"
                  field="endPercent"
                  labels={{
                    visible: false,
                    content: labelContent,
                  }}
                />

              </ChartSeries>
            </Chart>}
          
          </ResponsiveContainer>          
      
                    
          </>
                
    )

}
  export default AcctperfChartWidget

