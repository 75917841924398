import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import ConsolidationGrid from "./consolidationGrid";
import Loading from "./loading";
import NetoverflowGrid from './netoverflowGrid';

const NetOverdraftRpt = () => {
    console.log("in netoverdraftrpt")
    
    const [netOverdraft, setNetOverdraft] = React.useState();
    var tempToken = JSON.parse(localStorage.getItem("token"));
    const [session, setSession] = useState("");
    const [loading, setLoading] = useState(true);
    const [isResponse, setIsResponse] = useState(false);

    const [triggerColapse, setTriggerColapse] = useState(0);
    const [triggerExpand, setTriggerExpand] = useState(0);
    const [expandedState, setExpandedState] = React.useState(true);
    const [triggerExport, setTriggerExport] = useState(0);

    const refreshToken = async () => {
        let token = JSON.parse(localStorage.getItem("token"));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                authorization: `Bearer ${token.token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        await axios
            .post("/token/Refresh", postData)

            .then((response) => {
                tempToken = response.data;
                localStorage.setItem("token", JSON.stringify(response.data));

                GetnetOverdraftData();
            })
            .catch((error) => {
                if (error.code === "ERR_BAD_REQUEST") {
                    setSession("Session Expired");
                }

                console.log("my error is " + error);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                //let data = location.state;

                let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

                //setEmail(email);
                GetnetOverdraftData();

                //  console.log(data);
            } catch (error) {
                console.error(error.message);
            }
        };
        fetchData();
    }, []);

    const handleTriggerColapse = (event) => {
        setTriggerColapse((triggerColapse) => triggerColapse + 1);
        setExpandedState(false);
    };

    const handleTriggerExpand = (event) => {
        setTriggerExpand((triggerExpand) => triggerExpand + 1);
        setExpandedState(true);
    };
    const handleExcelExport = (event) => {
        setTriggerExport((triggerExport) => triggerExport + 1);
    };

    const GetnetOverdraftData = async () => {
        
        setLoading(true);
        console.log("GetnetOverdraftData here");
        let UserId = JSON.parse(localStorage.getItem("userId"));
        let lAcctId=localStorage.getItem("SelAcctId")==null?0:localStorage.getItem("SelAcctId");
        let AcctId = lAcctId ; //localStorage.getItem("SelAcctId");
        let CnsldtnId = localStorage.getItem("pConsId");
        let RoleTypeId = localStorage.getItem("UserRoleData");
        //let ModeId = localStorage.getItem("ModeId");
        let ModeId = 0;
        let RltnshpCd =localStorage.getItem("pRltncd")==null||localStorage.getItem("pRltncd")==undefined?"":localStorage.getItem("pRltncd");
        let token = tempToken;

        const postData = { UserId, CnsldtnId, AcctId, RoleTypeId, ModeId,RltnshpCd };
        const config = {
            headers: {
                authorization: `Bearer ${token.token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        
        await axios
            .post("/NetOverDraftLatest", postData, config)
            .then((response) => {
                
                setLoading(false);
                console.log(response.data);
                setNetOverdraft(response.data);
                setIsResponse(true);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    refreshToken();
                }
            });
    };

    if (loading) {
        return (
            <div className="container-fluid">
                <Loading />
            </div>
        );
    }
    return (
        <>
            <NetoverflowGrid dashboardData={netOverdraft}/>
        </>
    )
};
export default NetOverdraftRpt