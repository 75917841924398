import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu,ColumnMenuCheckboxFilter } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import { ResponsiveContainer } from 'recharts';
import {TimeWtdRtnRptGridDatavar} from './cstmRptMain';

const TimeWtdRtnRptGridWidget=()=>{
    const [topHoldingsdata, settopHoldingsdata] = useState(TimeWtdRtnRptGridDatavar);
    const createDataState = (dataState) => {
        return {
            result: process(topHoldingsdata.slice(0), dataState),
            dataState: dataState,
        };
    };
    let initialState = createDataState({
        take: 20,
        skip: 0,
    });
    const [result, setResult] = React.useState(initialState.result);
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [sort, setSort] = React.useState([]);
    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const NumberCellSixDecimal = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.000000")}
            </td>
        )
    }
    const menuWithExcelCheckOnly = (props) => {
        
      
        const props1 = {...props};
        
      
        
        return (
          <div>
      
            <ColumnMenuCheckboxFilter
              {...props1}
      
             
              data={TimeWtdRtnRptGridDatavar}
            
            />
          </div>)
      }
    return(
<div><h5 className='text-fitek p-1'>Time Weighted Return For Selected Period</h5>
        <Grid style={{ width: "100%", height: "100%" }}
        id='tileGrid'
            data={result}
            //scrollable={"scrollable"}
            onDataStateChange={dataStateChange}
            {...dataState}
            //sortable={true}
            resizable={true}
            //reorderable={true}
            className='page-break'
        >

            <Column field="groupHeader" menu={true} columnMenu={menuWithExcelCheckOnly} title="Asset Class" width="auto" filter={true} />
            <Column field="detailedLine" menu={true} columnMenu={menuWithExcelCheckOnly} title="Details" width="auto" filter={true} />
            <Column field="monthToDate" menu={true} columnMenu={ColumnMenu} title="Month to date (%)" width="auto"  cell={NumberCell} headerClassName='rightHeader' />

            <Column field="quarterToDate" menu={true} columnMenu={ColumnMenu} title="Quarter to date (%)" width="auto"  cell={NumberCell} headerClassName='rightHeader' />

            <Column field="yearToDate" menu={true} columnMenu={ColumnMenu} title="Year to date (%)" width="auto"  cell={NumberCell} headerClassName='rightHeader' />
            <Column field="oneYear" columnMenu={ColumnMenu} title="Last 1 year (%)" width="auto"  cell={NumberCell} headerClassName='rightHeader' />
            <Column field="threeYear" columnMenu={ColumnMenu} title="Last 3 years (%)" width="auto"  cell={NumberCell} headerClassName='rightHeader' />
            <Column field="fiveYear" columnMenu={ColumnMenu} title="Last 5 years (%)" width="auto"  cell={NumberCell} headerClassName='rightHeader' />
            <Column field="tenYear" columnMenu={ColumnMenu} title="Last 10 years (%)" width="auto"  cell={NumberCell} headerClassName='rightHeader' />
            <Column field="inception" columnMenu={ColumnMenu} title="Since inception (%)" width="auto"  cell={NumberCell} headerClassName='rightHeader' />

        </Grid>
</div>
    )
}

export default TimeWtdRtnRptGridWidget;

