import React from 'react'
import { useState, useEffect, useRef } from 'react';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {ResponsiveContainer} from 'recharts';

import {snapshotCmprsnDatavar,snapshotCmprsncolorDatavar} from './cstmRptMain';

const PortfolioSnpshotBarChartWidget = () => {

const psCmprsnFinalData =snapshotCmprsnDatavar;
const FormatLongNumber = ({ value }) => {
   
            if (value === 0) {
                return 0;
            }
            else {
                // for testing
                //value = Math.floor(Math.random()*1001);
    
                // hundreds
                if (value <= 999) {
                    return value;
                }
                // thousands
                else if (value >= 1000 && value <= 999999) {
                    return (value / 1000) + 'K';
                }
                // millions
                else if (value >= 1000000 && value <= 999999999) {
                    return (value / 1000000) + 'M';
                }
                // billions
                else if (value >= 1000000000 && value <= 999999999999) {
                    return (value / 1000000000) + 'B';
                }
                else
                    return value;
            }
        }
    const [chartType, setChartType] = useState("column");
    const [astModelChartdata, setastModelChartdata] = useState(snapshotCmprsnDatavar);
    const chartDefaultV4Colors = [
        "#235ee7",
        "#dce4ea",
        "#4ac9c9",
        "#d3dffb",
        "#7c9ff2",
        "#f3b021",
        "#f8d0c0",
    ];
    const labelContent = (e) => `${e.value.toFixed(2)}%`;
    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.marketPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.marketPercent.toFixed(2)}%`;
    };
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}%`;
    return (
        <>
<h5 className='text-fitek p-1'>Current Holdings Vs Portfolio Holdings


</h5>
        
            <ResponsiveContainer className='page-break'>
                {psCmprsnFinalData.length===0?<div>No Data Found.</div>:
            <Chart style={{ height: "350px" }}>
                            <ChartLegend position="bottom" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem
                                    labels={{
                                        visible: true,
                                        rotation: 85,
                                        format: "d",
                                    }}
                                //  categories={categoryAxis} 
                                />
                            </ChartCategoryAxis>
                            <ChartTooltip render={defaultTooltipRender} />
                            <ChartValueAxis>
                                <ChartValueAxisItem
                                    // title={{
                                    //     text: "Percentage",
                                    // }}
                                    min={0}
                                    labels={{
                                        visible: true,
                                        content: FormatLongNumber

                                    }}
                                />
                            </ChartValueAxis>
                            <ChartSeries>
                                <ChartSeriesItem
                                    type="column"
                                    data={psCmprsnFinalData.filter(x=>x.marketPercentCur!=0 || x.marketPercentSpc!=0)}
                                    categoryField="groupName"
                                    field="marketPercentCur"
                                    labels={{
                                        visible: true,
                                        content: FormatLongNumber,
                                    }}
                                    name='Current'
                                />
                                <ChartSeriesItem
                                    type="column"
                                    data={psCmprsnFinalData.filter(x=>x.marketPercentCur!=0 || x.marketPercentSpc!=0)}
                                    categoryField="groupName"
                                    field="marketPercentSpc"
                                    labels={{
                                        visible: true,
                                        content: FormatLongNumber,
                                    }}
                                    name='Snapshot'
                                />
                            </ChartSeries>
                        </Chart>}
            </ResponsiveContainer>
            </>
     
  )
}

export default PortfolioSnpshotBarChartWidget

