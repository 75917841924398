import * as React from "react";
import {
  GridColumnMenuSort,
  GridColumnMenuGroup,
  GridColumnMenuFilter,
  GridColumnMenuItemGroup,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
  GridColumnMenuCheckboxFilter
} from "@progress/kendo-react-grid";
import { filterBy,orderBy } from '@progress/kendo-data-query';
import { classNames } from "@progress/kendo-react-common";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";

export const CustomColumnMenuNoGrpChkBox= (props) => {

  let filterData=orderBy(props.data, [{ field: props.column.field, dir: "asc" }]);//JSON.parse(localStorage.getItem("filterData")) ;
    const handleColumnToggle = (state) => {
        props.onColumnLockToggle(props.column.field || "", state);
    
        if (props.onCloseMenu) {
          props.onCloseMenu();
        }
      };
const themes=useSelector(selectTheme);
  const [columns, setColumns] = React.useState(props.columns);
  const [columnsExpanded, setColumnsExpanded] = React.useState(false);
  const [filterExpanded, setFilterExpanded] = React.useState(false);
  const [groupEnabled, setGroupEnabled] = React.useState(false);
  const onToggleColumn = (id) => {
    const newColumns = columns.map((column, idx) => {
      return idx === id ? { ...column, show: !column.show } : column;
    });
    setColumns(newColumns);
  };

  const onReset = (event) => {
    event.preventDefault();
    const newColumns = props.columns.map((col) => {
      return { ...col, show: true };
    });
    setColumns(newColumns);
    props.onColumnsSubmit(newColumns);

    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    props.onColumnsSubmit(columns);

    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  const onMenuItemClick = () => {
    const value = !columnsExpanded;
    setColumnsExpanded(value);
    setFilterExpanded(value ? false : filterExpanded);
  };

  const onFilterExpandChange = (value) => {
    
    setFilterExpanded(value);
    setColumnsExpanded(value ? false : columnsExpanded);
  };

  const oneVisibleColumn = columns.filter((c) => c.show).length === 1;
  return (
    <div >
    
      {/* <GridColumnMenuSort {...props} /> */}
      {/* <GridColumnMenuFilter
        {...props}
        onExpandChange={onFilterExpandChange}
        expanded={filterExpanded}
      /> */}
       <GridColumnMenuCheckboxFilter
       style={{color:"red"}}
     // show={!columnsExpanded}
     onExpandChange={onFilterExpandChange}
       {...props}
        data={filterData}
        
      //expanded={columnsExpanded}
      />
     
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem
          title={"Columns"}
          iconClass={"k-i-columns"}
          onClick={onMenuItemClick}
        />
        <GridColumnMenuItemContent show={columnsExpanded} >
          <div className={"k-column-list-wrapper"}>
            <form onSubmit={onSubmit} onReset={onReset}>
              <div className={"k-column-list"}>
                {columns.map((column, idx) => (
                  <div key={idx} className={"k-column-list-item"}>
                    <span>
                      <input
                        id={`column-visiblity-show-${idx}`}
                        className="k-checkbox k-checkbox-md k-rounded-md"
                        type="checkbox"
                        readOnly={true}
                        disabled={column.show && oneVisibleColumn}
                        checked={column.show}
                        onClick={() => {
                          onToggleColumn(idx);
                        }}
                      />
                      <label
                        htmlFor={`column-visiblity-show-${idx}`}
                        className="k-checkbox-label"
                        style={{
                          userSelect: "none",
                        }}
                      >
                        {column.title}
                      </label>
                    </span>
                  </div>
                ))}
              </div>
              <div style={{backgroundColor:"grey"}} className={"k-actions k-hstack k-justify-content-stretch"}>
                
              <button
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  }
                  style={{backgroundColor:'#0D6EFD',
                    borderStyle: 'none'}}
                >
                  submit
                </button>
                <button
                  type={"reset"}
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  }
                >
                  reset
                </button>
              </div>
            </form>
          </div>

          
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
      {themes==='dark'?<style>
    {`
    .k-columnmenu-item {
  color: #47daff;
  background-color: #494949;
}
    .k-columnmenu-item {
  color: #47daff;
  background-color: #494949;
}
 
  button[type='reset']
  {
  background-color: #0D6EFD !important;
  border-style: none;
  }
.k-grid-md .k-grid-header .k-grid-header-menu{
  color: #FFFFFF;
}
.k-grid-md .k-grid-header .k-grid-header-menu:hover{
  color: #ffcb1fc9;
}

    `}
  </style>:
  <style>
    {`
    
    button[type='reset']
 {
  background-color: #0D6EFD !important;
  color: #ffffff;
  border-color: rgba(0, 255, 255, 0);
  border-style: none;
  
}
.k-grid-md .k-grid-header .k-grid-header-menu{
  color: #FFFFFF;
}
.k-grid-md .k-grid-header .k-grid-header-menu:hover{
  color: #ffcb1fc9;
}

    `}
  </style>
  }
    </div>
  );
};