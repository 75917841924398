import React from 'react'
import Logo from '../Images/Token.png';
const Tokenimg = () => {



  return (
    <div>
      <img src={Logo}></img>
    </div>
  )
}

export default Tokenimg
