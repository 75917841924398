import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import tokenreducer from '../features/auth/authSlice'
import themereducer from '../features/theme/themeSlice'
import darkthemeSlice from '../features/darktheme/darkthemeSlice'
export default configureStore({
  reducer: {
     counter: counterReducer,
    auth:tokenreducer,
    theme:themereducer,
    darkstyle:darkthemeSlice,
  },
})
