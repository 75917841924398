import React from "react";
import { useState, useEffect, useRef } from "react";

import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import Enumerable from 'linq';
import {fipoBondByMjrIndstry} from './cstmRptMain'
import {ResponsiveContainer} from 'recharts';

const FipoMjrIndusSecChartWidget = () => {
  const chartDefaultV4Colors = [
    "#00876c",
    "#6e9b75",
    "#a5af90",
    "#cbc6b9",
    "#c9a47e",
    "#d07958",
  ];
  const [chartType, setChartType] = React.useState("pie");
  const [selAcct,SetselAcct]=React.useState(JSON.parse(localStorage.getItem('AcctSelected')));
  let table5 = [];
  table5 = Enumerable.from(fipoBondByMjrIndstry)
    .where((w) => w.acctId === selAcct.acctId)
    .toArray();
  
  const labelContentMjr = (props) => {
    let formatedNumber = Number(props.dataItem.sectorPct).toLocaleString(
      undefined,
      {
        style: "percent",
        minimumFractionDigits: 2,
      }
    );
    return `${props.category}  ${(props.dataItem.sectorPct * 100).toFixed(2)}%`;
  };
  return (
    <div><h5 className='text-fitek p-1'>Bonds: By Industry Sector(Major Industry)
    </h5>
    <ResponsiveContainer className='page-break'>  
    
      {/* <div className="card-body">

      <div className="mx-1 my-1 py-1"> */}
      {table5.length===0?<div>No Data Found.</div>:
        <Chart seriesColors={chartDefaultV4Colors} style={{ height: "400px" }}>
          {/* <ChartTitle text="Major Asset Chart" /> */}
          <ChartLegend position="bottom" />

          <ChartSeries>
            <ChartSeriesItem
              type={chartType}
              data={table5}
              autoFit={true}
              field="sectorPct"
              categoryField="indSector"
              labels={{
                visible: true,
                content: labelContentMjr,
              }}
            />
          </ChartSeries>
        </Chart>}
      {/* </div>
      </div> */}
      </ResponsiveContainer></div>
  );
};
export default FipoMjrIndusSecChartWidget;
