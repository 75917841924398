import React from 'react';
import { formatNumber, formatDate } from '@telerik/kendo-intl';

  import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
  } from "@progress/kendo-react-charts";
  import {fimMatrtyDtVsCallPutDt} from './cstmRptMain';
  import {ResponsiveContainer} from 'recharts';

const FimMtrDtVsClPutdtlChrtWidget= () => {

    const [chartmatVsCallPutData, setMatVsCallPutData] = React.useState(fimMatrtyDtVsCallPutDt.sort((a, b) => a.mtrtyYr - b.mtrtyYr));
    const defaultTooltipRender = ({ point }) => `${formatNumber(point.value, "##,#.00")}`;
    const labelContent = (e) => `${formatNumber(e.value, "##,#.00")}`;
    const FormatLongNumber = ({ value }) => {

      if (value === 0) {
        return 0;
      }
      else {
        // for testing
        //value = Math.floor(Math.random()*1001);
  
        // hundreds
        if (value <= 999) {
          return value;
        }
        // thousands
        else if (value >= 1000 && value <= 999999) {
          return (value / 1000) + 'K';
        }
        // millions
        else if (value >= 1000000 && value <= 999999999) {
          return (value / 1000000) + 'M';
        }
        // billions
        else if (value >= 1000000000 && value <= 999999999999) {
          return (value / 1000000000) + 'B';
        }
        else
          return value;
      }
    }
    return (
      <div><h5 className='text-fitek p-1'>Maturity Date Vs Call / Put Date</h5>
      <ResponsiveContainer className='page-break'>   
      {chartmatVsCallPutData.length===0?<div>No Data Found.</div>: 
                <Chart style={{ height: "350px" }}>
                  {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
                  <ChartLegend position="bottom" />
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      labels={{
                        visible: true,
                        rotation: 85,
                        format: "d",
                      }}
                    //  categories={categoryAxis} 
                    />
                  </ChartCategoryAxis>
                  <ChartTooltip render={defaultTooltipRender} />
                  <ChartValueAxis>
                    <ChartValueAxisItem
                      // title={{
                      //     text: "Percentage",
                      // }}
                      min={0}
                      labels={{
                        visible: true,
                        content: FormatLongNumber

                      }}
                    />
                  </ChartValueAxis>
                  <ChartSeries>
                    <ChartSeriesItem
                      type="column"
                      data={chartmatVsCallPutData}
                      categoryField="callOrPutYr"
                      field="mtrShares"
                      labels={{
                        visible: false,
                        content: labelContent,
                      }}
                      name='Maturity Shares'
                    />
                    <ChartSeriesItem
                      type="column"
                      data={chartmatVsCallPutData}
                      categoryField="callOrPutYr"
                      field="callPutShares"
                      labels={{
                        visible: false,
                        content: labelContent,
                      }}
                      name='Call/Put Shares'
                    />
                  </ChartSeries>
                </Chart>}
                </ResponsiveContainer>
    </div>
  )
}
export default  FimMtrDtVsClPutdtlChrtWidget
