import { createSlice } from "@reduxjs/toolkit"

const darkthemeSlice = createSlice({
    name: 'style',
    initialState: { style:"" },
    reducers: {
        setdarkstyle: (state,action) => {
            
            state.style = action.payload;
            
        }
    },
})

export const { setdarkstyle } = darkthemeSlice.actions

export default darkthemeSlice.reducer

export const selectdarkstyle = (state) => state.darkstyle.style
