import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import { ResponsiveContainer } from 'recharts';
import { acctPrflSmryDatavar } from './cstmRptMain';
const AccountProfileSmryWidget = () => {
    const menuWithExcelCheckOnly = (props) => {
        
      
        const props1 = {...props};
        
      
        
        return (
          <div>
      
            <ColumnMenuCheckboxFilter
              {...props1}
      
             
              data={acctPrflSmryDatavar}
            
            />
          </div>)
      }

    const [topHoldingsdata, settopHoldingsdata] = useState(acctPrflSmryDatavar);
    const createDataState = (dataState) => {
        return {
            result: process(topHoldingsdata.slice(0), dataState),
            dataState: dataState,
        };
    };
    let initialState = createDataState({
        take: 20,
        skip: 0,
    });
    const [result, setResult] = React.useState(initialState.result);
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [sort, setSort] = React.useState([]);
    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
    };

    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const NumberCellSixDecimal = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.000000")}
            </td>
        )
    }



    return (

<div><h5 className='text-fitek p-1'>Account Profile Summary</h5>
        <Grid style={{ width: "auto", height: "auto" }}
        id='tileGrid'
            data={result}
            scrollable={"scrollable"}
            onDataStateChange={dataStateChange}
            {...dataState}
            sortable={true}
            resizable={true}
            reorderable={true}
            
        >

            <Column field="groupName" menu={true} title="Asset Class" width="400px" filter={true} columnMenu={menuWithExcelCheckOnly} />
            <Column field="txCstAmt" menu={true} title="Total Cost($)" width="auto" headerClassName='rightHeader'  cell={NumberCell} columnMenu={ColumnMenu}/>
            <Column field="market" menu={true} title="Market Value($)" width="auto" headerClassName='rightHeader'  cell={NumberCell} columnMenu={ColumnMenu}/>

            <Column field="income" menu={true} title="Income($)" width="auto" headerClassName='rightHeader'  cell={NumberCell} columnMenu={ColumnMenu}/>

            <Column field="yield" menu={true} title="Yield(%)" width="auto"  headerClassName='rightHeader' cell={NumberCell} columnMenu={ColumnMenu}/>
            <Column field="marketPercent" title="Market Value(%)" width="auto" headerClassName='rightHeader'  cell={NumberCell} columnMenu={ColumnMenu}/>

        </Grid>

</div>



    )
}

export default AccountProfileSmryWidget;