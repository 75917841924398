import React from 'react'
import advisorLogo from '../Images/adbiglogo.png';
import advisorlightlogo from '../Images/GwesLightLogo.png'
import { useSelector } from 'react-redux';
import { selectTheme } from '../features/theme/themeSlice';
const AdvisorLogo = () => {
  const themes=useSelector(selectTheme)
  return (
    <div>
          {themes!=='dark'?<img style={{height:'28px'}}  src={advisorLogo}></img>:
          <img style={{height:'53px'}}  src={advisorlightlogo}></img>}
    </div>
  )
}

export default AdvisorLogo
