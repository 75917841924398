import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Enumerable from 'linq';
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { fipoBondQultySctr } from './cstmRptMain';
import {ResponsiveContainer} from 'recharts';

const FipoBondQultySctrWidget = () => {
  const chartDefaultV4Colors = [
    "#00876c",
    "#6e9b75",
    "#a5af90",
    "#cbc6b9",
    "#c9a47e",
    "#d07958",
  ];
  let selAcct = JSON.parse(localStorage.getItem('AcctSelected'));
  let table2 = [];
  table2 = Enumerable.from(fipoBondQultySctr).where(w => w.acctId === selAcct.acctId).toArray();
  const [chartType, setChartType] = useState("pie");
  const labelContent1 = (props) => {

    let formatedNumber = Number(props.dataItem.rtngPercent).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return `${props.category}  ${(props.dataItem.rtngPercent * 100).toFixed(2)}%`;
  };
  return (
    <div><h5 className='text-fitek p-1'>Bond Quality Sector
    </h5>
    <ResponsiveContainer className='page-break'>
    {/* <div className="card-body">
      <div className="mx-1 my-1 py-1"> */}
      {table2.length===0?<div>No Data Found.</div>:
        <Chart seriesColors={chartDefaultV4Colors} style={{ height: "340px" }}>
          <ChartLegend position="bottom" />

          <ChartSeries>
            <ChartSeriesItem
              type={chartType}
              data={table2}
              autoFit={true}
              field="rtngPercent"
              categoryField="rating"
              labels={{
                visible: true,
                content: labelContent1,
              }}
            />
          </ChartSeries>
        </Chart>}
      {/* </div>
    </div> */}
    </ResponsiveContainer>
</div>
  )
}
export default FipoBondQultySctrWidget
