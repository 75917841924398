import React from 'react'
import { ResponsiveContainer } from 'recharts'
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
  } from "@progress/kendo-react-charts";
  import { useState, useEffect, useRef } from 'react';
  import Enumerable from 'linq';
  import {astVsModelData} from './cstmRptMain';
const PortfolioVsModelWidget = () => {

  //const assetVsColorModel = astVsModelData.ocAssetColorModel;
 
  // const assetVsModelFinalData = Enumerable.from(astVsModelData)
  //   .join(
  //     assetVsColorModel,
  //     pk => pk.groupId,
  //     fk => fk.mjrAstTypeId,
  //     (left, right) => ({ ...left, ...right })
  //   )
  //   .toArray();
    //setAlldata(assetVsModelFinalData);
  const [allData, setAlldata] = useState(Enumerable.from(astVsModelData).where(w => w.prtfolio >= 0)
    .toArray());
    const labelContent = (e) => `${e.value.toFixed(2)}%`;
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}%`;
  return (<>
  <h5 className='text-fitek p-1'>Portfolio Vs Model



</h5>      
    <ResponsiveContainer className='page-break'>
    {allData.length===0?<div>No Data Found.</div>:
       <Chart
                      // seriesColors={chartDefaultV4Colors}
                      zoomable={false}
                    >
                        <ChartLegend position="bottom" />
                      <ChartValueAxis>
                        <ChartValueAxisItem
                          // title={{
                          //     text: "Percentage",
                          // }}
                          min={0}
                         // max={100}
                        />
                      </ChartValueAxis>
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                          //    title={{
                          //     text: "category",
                          // }}
                          labels={{
                            visible: true,
                            rotation: 50,
                            format: "d",

                          }}

                        //  categories={categoryAxis} 
                        />
                      </ChartCategoryAxis>
                      <ChartSeriesDefaults
                        type="column"
                        labels={{
                          visible: true,
                          format: "n2",
                        }}
                      />

                      {/* <ChartValueAxis>
                    <ChartValueAxisItem crosshair={crosshair} />
                </ChartValueAxis> */}
                      <ChartTooltip render={defaultTooltipRender} />

                      <ChartSeries>

                        <ChartSeriesItem
                          data={allData}
                         // colorField='chartColorCOde'
                          type='column'
                          field='prtfolioWeigh'
                          categoryField='descption'
                          //name='Portfolio'
                          // aggregate='sum'
                          labels={{
                            visible: true,
                            content: labelContent,
                          }}
                          name='Portfolio'
                        />

                        <ChartSeriesItem
                          data={allData}
                          // colorField='chartColorCOde'
                          type='column'
                          field='mdlWegh'
                          categoryField='descption'
                          //name='Model'
                          // aggregate='sum'
                          labels={{
                            visible: true,
                            content: labelContent,
                          }}
                          name='Model'
                        />

                      </ChartSeries>

                    </Chart>}
    </ResponsiveContainer></>
  )
}

export default PortfolioVsModelWidget
