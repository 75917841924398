import React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import TextField from "@mui/material/TextField";
import { useDraggable, Icon } from "@progress/kendo-react-common";
import { style } from "@mui/system";
import LogoPage from "./logoPage";
import Tokenimg from "./tokenimg";
import {
  FaKey,
  FaLock,
  FaMicrosoft,
  FaUserAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  Outlet,
  useParams,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";

const UserToken = () => {
  const [errorMessages, setErrorMessages] = useState({});
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("LoggedInFlag") === "true" &&
      localStorage.getItem("LoggedInFlag") !== null
    ) {
      let isAuth = JSON.parse(localStorage.getItem("token"));
      if (isAuth && isAuth !== null) {
        if (isAuth.token && isAuth.token !== null) {
          localStorage.removeItem("token");
          localStorage.setItem("LoggedInFlag", false);
          // if(location.state.IsLocked==1)
          //   navigate("/",{ state: location.state });
          //  else
          //  {
          navigate("/");
          //}
        }
      }
    }
  }, []);
  const validateToken = async (event) => {
    event.preventDefault();
    setErrorMessages({});

    var { txtToken } = document.forms[0];
    if (txtToken.value == "") {
      setErrorMessages({ name: "Token", message: "Please enter Token." });
    } else {
      let TokenId = txtToken.value;
      let EmailAdrs = JSON.parse(localStorage.getItem("email"));
      let token = JSON.parse(localStorage.getItem("token"));
      let Resend = 0;
      const data = { EmailAdrs, TokenId, Resend };

      //localStorage.setItem('email', JSON.stringify(Username));
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      await axios.post("/RsaToken", data, config).then((response) => {
        // console.log(response);

        if (response.statusText === "OK" || response.statusText === "") {
          let result = response.data;

          if (result.statusCode == 0 || result.statusCode == -1) {
            localStorage.setItem("LoggedInFlag", true);
            navigate("/dashboard");
          } else if (result.statusCode == 2) {
            setErrorMessages({
              name: "Token",
              message: "User Locked.",
              type: "Error",
            });
            signOut();
          } else if (result.statusCode == 3) {
            setErrorMessages({
              name: "Token",
              message: "Invalid Token.",
              type: "Error",
            });
          } else {
            setErrorMessages({
              name: "Token",
              message: response.statusText,
              type: "Error",
            });
          }
        }
      });
    }
  };

  const resendToken = async (event) => {
    event.preventDefault();
    setErrorMessages({});

    var { txtToken } = document.forms[0];

    let TokenId = 0;
    //txtToken.value;
    let EmailAdrs = JSON.parse(localStorage.getItem("email"));
    let token = JSON.parse(localStorage.getItem("token"));
    let Resend = 1;
    const data = { EmailAdrs, TokenId, Resend };

    //localStorage.setItem('email', JSON.stringify(Username));
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios.post("/RsaToken", data, config).then((response) => {
      // console.log(response);

      if (response.statusText === "OK" || response.statusText === "") {
        let result = response.data;

        if (result.statusCode == 0) {
          setErrorMessages({
            name: "Token",
            message: "OTP sent successully.",
            type: "Success",
          });
        } else {
          setErrorMessages({
            name: "Token",
            message: response.statusText,
            type: "Error",
          });
        }
      }
    });
  };

  const signOut = () => {
    //const LockedMsg={LockedMsg:"User Locked",IsLocked:1};

    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
    navigate("/", { state: { lockedmsg: "User Locked", islocked: true } });
  };

  const renderErrorMessage = (name) => {
    if (name === errorMessages.name && errorMessages.type === "Success") {
      return (
        <div className="text-center" style={{ color: "green" }}>
          {errorMessages.message}
        </div>
      );
    } else {
      return <div className="error text-center">{errorMessages.message}</div>;
    }
  };

  // const renderErrorMessage = (name) =>
  // name === errorMessages.name && (
  //     <div className="error text-center">{errorMessages.message}</div>
  // );

  return (
    <div>
      <div className="app">
        <div>
          <div className="login-form">
            <div className="text-center">
              <LogoPage />
            </div>
            <div className="text-center pb-2 pt-2 fs-5">
              Two Factor Authentication
            </div>

            <div className="text-center">
              <Tokenimg />

              <div className="text-center pb-2 pt-2">
                Please enter the token received on your email address
              </div>
            </div>

            <div className="form pb-2">
              <form>
                <div className="input-container">
                  <div className="input-group pb-2">
                    <TextField
                      sx={{
                        // Root class for the input field
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#2e2e2e",
                          },

                          // Class for the border around the input field
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#999999",
                            borderWidth: "1px",
                          },
                          // Class for the border around the input field

                          "& .MuiOutlinedInput-notchedOutline:focused": {
                            borderColor: "#2e2e2e !important",
                            borderWidth: "1px",
                          },
                        },
                        // Class for the label of the input field
                      }}
                      className="form-control"
                      name="txtToken"
                      variant="outlined"
                      label="Token"
                      size="small"
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }}
                      InputLabelProps={{
                        style: { color: "#808080" },
                      }}
                      // inputProps={{ maxLength: 6 }}
                    ></TextField>
                  </div>
                </div>

                <div className="text-center m-1 p-2">
                  <div className="button-container pt-1">
                    <input
                      type="submit"
                      onClick={validateToken}
                      className="btn btn-outline-primary w-50 p-2 m-1"
                      value="Authenticate"
                    ></input>
                  </div>

                  <a
                    href="#"
                    className="pt-3 pb-2 d-block text-center text-dark"
                    onClick={resendToken}
                  >
                    Resend Token
                  </a>
                </div>
                <div className="row mb-1 text-md">
                  {renderErrorMessage("Token")}
                </div>
              </form>

              <div className="text-center pt-3 text-success p-1 m-1">
                <FaLock className="" />
              </div>

              <div className="text-muted mt-1 text-center">
                Fi-Tek LLC, 2023
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserToken;
