import React from "react";
import { useState, useEffect, useRef, useMemo } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade, Slide, Zoom } from "@progress/kendo-react-animation";
import axios from "axios";
import { process, filterBy } from "@progress/kendo-data-query";
import { Button, Card } from "@mui/material";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import {
  FaCartPlus,
  FaFileExcel,
  FaFilePdf,
  FaMinus,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
} from "react-icons/fa";
import Enumerable from "linq";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { Pager } from "@progress/kendo-react-data-tools";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import { Button as ButtonKendo } from "@progress/kendo-react-buttons";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { SiTruenas } from "react-icons/si";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { pdf } from "@progress/kendo-drawing";

const aggregates = [
  // {
  //   field: "p1CashBlncAmt",
  //   aggregate: "sum",
  // },
  {
    field: "mrktVlAmt",
    aggregate: "sum",
  },
  {
    field: "ytdLngGainAmt",
    aggregate: "sum",
  },
  {
    field: "ytdLngLossAmt",
    aggregate: "sum",
  },
  {
    field: "ytdShrtGainAmt",
    aggregate: "sum",
  },
  {
    field: "ytdShrtLossAmt",
    aggregate: "sum",
  },
  {
    field: "ytdTotalLngAmt",
    aggregate: "sum",
  },
  {
    field: "ytdTotalShrtAmt",
    aggregate: "sum",
  },
  {
    field: "ytdTotalAmt",
    aggregate: "sum",
  },
  {
    field: "p1CashBlncAmt",
    aggregate: "sum",
  },

  {
    field: "p2CashBlncAmt",
    aggregate: "sum",
  },
  {
    field: "cashVlAmt",
    aggregate: "sum",
  },
  {
    field: "totalCash",
    aggregate: "sum",
  },
  {
    field: "totalUninvestedCash",
    aggregate: "sum",
  },
  {
    field: "totalLiqPriBal",
    aggregate: "sum",
  },
];
const DATA_ITEM_KEY = "seqno";
const SELECTED_FIELD = "selected";
const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const ConsolidationGrid = ({
  data,
  flag,
  triggerExport,
  triggerExpand,
  triggerColapse,
  triggerColSave,
  triggerClearfilter,
  AcctConsolidationReportData,
  populateAcctConsolidationReportData,
}) => {
  var tempToken = JSON.parse(localStorage.getItem("token"));

  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const [locked, setLocked] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState(true);
  const [columnLatest, setColumnLatest] = React.useState(null);

  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
    setColumnLatest(props.target._columns);
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }
    return newColumnsState;
  };
  const themes = useSelector(selectTheme);
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const initialGroup = [
    {
      field: "cnsldtnNmFilter",
    },
  ];

  const CustomGroupFooter = (props) => {
    const field = props.field || "";

    let mvSumMinor = props.aggregates.group.items
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);

    return `${formatNumber(mvSumMinor, "##,#.00")}`;
  };
  const defaultColumns = [
    // {
    //   title: "Consolidation",
    //   field: "cnsldtnNmFilter",
    //   minWidth: 225,
    //   show: true,
    //   locked: true,
    // },
    {
      title: "Account Number",
      field: "extrnlAcctId",
      minWidth: 500,
      // show: localStorage.getItem("SelAcctId") > 0 ? false : true,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: -1,
    },
    {
      title: "Account Name",
      field: "shrtNm",
      minWidth: 180,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 0,
    },

    {
      title: "Owner",
      field: "tpOwner",
      minWidth: 180,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 1,
    },

    {
      title: "Admin.",
      field: "tpAdmin",
      minWidth: 170,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 2,
    },
    {
      title: "Inv. Off.",
      field: "tpInvOff",
      minWidth: 225,
      show: true,
      filter: "text",

      locked: false,
      orderIndex: 3,
    },
    {
      title: "Mjr. Acct. Type",
      field: "mjrAcctTypNm",
      minWidth: 210,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 4,
    },
    {
      title: "Mnr. Acct. Type",
      field: "mnrAcctTypNm",
      minWidth: 210,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 5,
    },
    {
      title: "Inception Date",
      field: "incptnDt",
      minWidth: 180,
      show: true,
      filter: "date",
      locked: false,
      orderIndex: 6,
    },
    {
      title: "Inv. Objective",
      field: "invstmntObjctvNm",
      minWidth: 220,
      show: true,
      filter: "text",
      locked: false,
      orderIndex: 7,
    },
    {
      title: "Market Value ($)",
      field: "mrktVlAmt",
      minWidth: 220,
      show: true,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 8,
    },
    {
      title: "YTD Long Gain Amt.($)",
      field: "ytdLngGainAmt",
      minWidth: 240,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 9,
    },
    {
      title: "YTD Long Loss Amt.($)",
      field: "ytdLngLossAmt",
      minWidth: 240,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 10,
    },
    {
      title: "YTD Short Gain Amt.($)",
      field: "ytdShrtGainAmt",
      minWidth: 240,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 11,
    },
    {
      title: "YTD Short Loss Amt.($)",
      field: "ytdShrtLossAmt",
      minWidth: 240,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 12,
    },
    {
      title: "YTD Total Long-Term($)",
      field: "ytdTotalLngAmt",
      minWidth: 240,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 13,
    },
    {
      title: "YTD Total Short-Term($)",
      field: "ytdTotalShrtAmt",
      minWidth: 240,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 14,
    },
    {
      title: "YTD Total($)",
      field: "ytdTotalAmt",
      minWidth: 300,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 15,
    },
    {
      title: "Principal($)",
      field: "p1CashBlncAmt",
      minWidth: 160,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 16,
    },
    {
      title: "Income($)",
      field: "p2CashBlncAmt",
      minWidth: 160,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 17,
    },
    {
      title: "Sweep Balance($)",
      field: "cashVlAmt",
      minWidth: 200,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 18,
    },
    // sweepBalance,
    // totalCash,
    // totalUninvestedCash,
    {
      title: "Total Cash($)",
      field: "totalCash",
      minWidth: 170,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 19,
    },
    {
      title: "Total Uninvested Cash($)",
      field: "totalUninvestedCash",
      minWidth: 245,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 20,
    },
    {
      title: "Total Liquid Principal Balance($)",
      field: "totalLiqPriBal",
      minWidth: 300,
      groupFooter: CustomGroupFooter,
      groupFooterCellOptions: { textAlign: "right", format: "#,##0.00" },
      cellOptions: { format: "#,##0.00" },
      headerCellOptions: { textAlign: "right" },
      show: true,
      filter: "numeric",
      headerClassName: "rightHeader",
      aggregate: "sum",
      locked: false,
      orderIndex: 21,
    },
  ];
  console.log("tab:", localStorage.getItem("activeTab"));

  const CustomGroupHeader = (props) => {
    return `${props.value}`;
  };
  let loadedColumns = localStorage.getItem("consgridColumns");
  //const GridColumns = defaultColumns;
  //const GridColumns = flag ? defaultColumns: defaultColumns;
  debugger;
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: 4000,
    skip: 0,
    group: [{ field: "cnsldtnNmFilter" }],
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    //group: [{ field: 'pmrDesc' }]
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  // useEffect(() => {

  //   setResult(processWithGroups(data, initialState.dataState));
  // });
  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );

  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(GridColumns);

  const [asOfDate, setasOfDate] = useState(0);
  let pageSize = 10;
  const [page, setPage] = React.useState({
    skip: 0,
    take: Number.MAX_VALUE,
  });
  const dataExport = process(data, {
    group: initialGroup,
  }).data;
  let _pdfExport;
  const saveColumnStateDb = async (currentColumnsState) => {
    //Storing column settings in DB
    //
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 36;
    let ColumnSettings = currentColumnsState;
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    //
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };

  const saveColumnsState = () => {
    const columns = stateColumns;
    console.log("save called!!");
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem("consgridColumns", currentColumnsState);
    saveColumnStateDb(currentColumnsState);
  };
  useMemo(() => {
    if (triggerColSave) {
      saveColumnsState();
    }
  }, [triggerColSave]);
  // useEffect(() => {
  //   saveColumnsState(stateColumns);
  // }, [stateColumns]);

  const dataStateChange = (event) => {
    if (
      event.dataState.filter !== undefined &&
      event.dataState.filter !== null
    ) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumns.length; j++) {
        if (stateColumns[i].field === defaultColumns[j].field)
          stateColumns[i].headerClassName =
            defaultColumns[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }

    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });
    debugger;

    setResultExport(processWithGroups(data, updatedStateExcel.dataState));
    setDataState(updatedState.dataState);
    var pdfdata = AcctConsolidationReportData;
    pdfdata = filterBy(data, updatedState.dataState.filter);

    populateAcctConsolidationReportData(pdfdata);
    console.log("pdfdatainconsolidation", pdfdata);
    if (
      event.dataState.sort &&
      event.dataState.sort.length > 0 &&
      event.dataState.sort[0].field == "incptnDt"
    ) {
    } else {
      setResult(processWithGroups(data, updatedState.dataState));
    }
    if (event.dataState.sort) {
      // event.dataState.sort = event.dataState.sort.map((sort) => {
      debugger;
      if (
        event.dataState.sort.length > 0 &&
        event.dataState.sort[0].field == "incptnDt"
      ) {
        debugger;
        if (event.dataState.sort[0].dir === "asc") {
          for (var i = 0; i < updatedState.result.data.length; i++) {
            result.data[i].items = result.data[i].items.sort(
              (a, b) => new Date(a.incptnDt) - new Date(b.incptnDt)
            );
          }
          //updatedState.result.data.sort((a, b) => new Date(a.incptnDt) - new Date(b.incptnDt));
        } else if (event.dataState.sort[0].dir === "desc") {
          for (var i = 0; i < updatedState.result.data.length; i++) {
            result.data[i].items = result.data[i].items.sort(
              (a, b) => new Date(b.incptnDt) - new Date(a.incptnDt)
            );
          }
        }
      }
    }
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const exportPDF = () => {
    //let oldDataState = { ...dataState };
    // if (pdfExportComponent.current) {
    //setDataState({ ...dataState, skip: 0, take: Number.MAX_VALUE });

    _pdfExport.save();

    // setTimeout(() => {
    //   setDataState(oldDataState);
    // });
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({
      ...result,
      data: [...result.data],
    });
  };
  const handleColapse = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = false;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(false);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const handleExpand = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = true;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(true);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  useEffect(() => {
    handleExpand();
  }, []);
  useMemo(() => {
    if (triggerClearfilter) {
      debugger;
      let updatedState = createDataState({ ...dataState, filter: null }); //here the filter object is set to null

      setResult(updatedState.result);
      setDataState(updatedState.dataState);
      var pdfdata = AcctConsolidationReportData;
      pdfdata = filterBy(data, updatedState.dataState.filter);
      populateAcctConsolidationReportData(pdfdata);
      for (var i = 0; i < stateColumns.length; i++) {
        if (stateColumns[i].headerClassName != undefined)
          stateColumns[i].headerClassName = stateColumns[
            i
          ].headerClassName.replaceAll("active", "");
      }
      console.log(dataState);
    }
  }, [triggerClearfilter]);
  useMemo(() => {
    if (triggerExport) {
      excelExport();
    }
  }, [triggerExport]);

  useMemo(() => {
    if (triggerExpand) {
      handleExpand();
    }
  }, [triggerExpand]);
  useMemo(() => {
    if (triggerColapse) {
      handleColapse();
    }
  }, [triggerColapse]);
  useEffect(() => {
    if (localStorage.getItem("SelAcctId") > 0) {
      defaultColumns[0].show = false;
    }
  }, []);

  const getCells = (columns, cellProps) => {
    debugger;
    let cells = [];
    console.log("Columns values:", columns);
    columns.forEach((column) => {
      var colStatus = Enumerable.from(stateColumns)
        .where((w) => w.field === column.field)
        .toArray();
      //if( colStatus.length!=0 && colStatus[0].show){
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem?.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.

      const columns = [
        { field: "cnsldtnNmFilter" },
        { field: "extrnlAcctId" },
        { field: "shrtNm" },
        { field: "tpOwner" },
        { field: "mjrAcctTypNm" },
        { field: "mnrAcctTypNm" },
        { field: "incptnDt" },
        { field: "tpAdmin" },
        { field: "tpInvOff" },

        { field: "mjrAcctTypNm" },
        { field: "mnrAcctTypNm" },
        { field: "incptnDt" },
        { field: "invstmntObjctvNm" },
        { field: "mrktVlAmt", aggregate: "sum" },
        { field: "ytdLngGainAmt", aggregate: "sum" },
        { field: "ytdLngLossAmt", aggregate: "sum" },
        { field: "ytdShrtGainAmt", aggregate: "sum" },
        { field: "ytdShrtLossAmt", aggregate: "sum" },
        { field: "ytdTotalLngAmt", aggregate: "sum" },
        { field: "ytdTotalShrtAmt", aggregate: "sum" },
        { field: "ytdTotalAmt", aggregate: "sum" },
        { field: "p1CashBlncAmt", aggregate: "sum" },
        { field: "p2CashBlncAmt", aggregate: "sum" },
        { field: "cashVlAmt", aggregate: "sum" },
        { field: "totalCash", aggregate: "sum" },
        { field: "totalUninvestedCash", aggregate: "sum" },
        { field: "totalLiqPriBal", aggregate: "sum" },
      ];
      if (!tdElement.props.colSpan) {
        return <td></td>;
      }
      let currentColumns = stateColumns.filter((c) => c.show);
      currentColumns = currentColumns.map((col) => {
        let aggrCol = columns.find((c) => {
          return c.field == col.field;
        });
        if (aggrCol && aggrCol.aggregate) {
          console.log("aggregate");
          return {
            ...col,
            aggregate: aggrCol.aggregate,
          };
        } else {
          return col;
        }
      });
      debugger;
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - currentColumns.length}
          ></td>
          {getCells(
            currentColumns.sort((a, b) => {
              return a.orderIndex - b.orderIndex;
            }),
            cellProps
          )}
        </>
      );
    }
    if (cellProps.rowType === "groupFooter") {
      if (cellProps.field === "mrktVlAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.mrktVlAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "ytdLngGainAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.ytdLngGainAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "ytdLngLossAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.ytdLngLossAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "ytdShrtGainAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.ytdShrtGainAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "ytdShrtLossAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.ytdShrtLossAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "ytdTotalLngAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.ytdTotalLngAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "ytdTotalShrtAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.ytdTotalShrtAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "ytdTotalAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.ytdTotalAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }

      if (cellProps.field === "p1CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.p1CashBlncAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "p2CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.p2CashBlncAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "cashVlAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.cashVlAmt.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "totalCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.totalCash.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "totalUninvestedCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.totalUninvestedCash.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "totalLiqPriBal") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.totalLiqPriBal.sum,
              "##,#.00"
            )}
          </td>
        );
      }
    }

    if (cellProps.rowType === "data") {
      if (cellProps.field === "mrktVlAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "ytdLngGainAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "cashVlAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "ytdLngLossAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "ytdShrtGainAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "ytdShrtLossAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "ytdTotalLngAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "ytdTotalShrtAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "ytdTotalAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p1CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p2CashBlncAmt") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "totalCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "totalUninvestedCash") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "totalLiqPriBal") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            data-grid-col-index={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "incptnDt") {
        let cdt = new Date(cellProps.dataItem["incptnDt"]);
        return (
          <td
            style={{ textAlign: "left" }}
            data-grid-col-index={cellProps.columnIndex}
          >
            {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
            {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
          </td>
        );
      }
    }

    return tdElement;
  };
  const onSelectionChange = (event) => {
    // data.map((item) => {
    //     item.selected = false;
    // });

    let theSelectedItem = event.dataItems[event.endRowIndex];

    let newData = data.map((item) => {
      // item.selected = false;
      if (item.extrnlAcctId === theSelectedItem.extrnlAcctId) {
        item.selected = !item.selected;
      }
      return item;
    });
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const NumberCell = (props) => {
    if (props.field === "branch") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountType") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "accountName") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "asset") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "tckrSymbl") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "cusip") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "pmrDesc") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "shares") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "cost") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "market") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "unrGainLoss") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "estAnnInc") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "yield") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "accruedInterest") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "principalCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "incomeCash") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "investedIncome") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "investmentObjective") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "administrator") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "investmentOfficer") {
      return (
        <td style={{ textAlign: "left" }}>{props.dataItem[props.field]}</td>
      );
    }
    if (props.field === "rate") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "txCstAmt") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "yldToCost") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "costPerShare") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
    if (props.field === "price") {
      return (
        <td style={{ textAlign: "right" }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      );
    }
  };

  const windowHeight = useRef(window.innerHeight);

  var excelTitle =
    localStorage.getItem("SelAcctId") == 0
      ? "Relationship:" + localStorage.getItem("pConsName")
      : "Account Number:" + localStorage.getItem("SelAcctNm");
  console.log("Consolidation result:", result);

  var stateColExport = stateColumns;
  var columnLatest2 = columnLatest;
  if (
    columnLatest2 !== null &&
    columnLatest2.filter((x) => x.field !== "cnsldtnNmFilter")
  ) {
    columnLatest2 = [
      {
        title: "",
        field: "cnsldtnNmFilter",
        groupHeader: CustomGroupHeader,
        show: false,
      },
      ...columnLatest2,
    ];
  }
  if (stateColExport.filter((x) => x.field !== "cnsldtnNmFilter")) {
    stateColExport = [
      {
        title: "",
        field: "cnsldtnNmFilter",
        groupHeader: CustomGroupHeader,
        show: false,
      },
      ...stateColExport,
    ];
  }
  stateColExport.sort(function (a, b) {
    return a.orderIndex - b.orderIndex;
  });
  return (
    // <div className="row my-2">
    //   <div className="col col-md-12 col-sm-12 py-2">
    //     <div className="mx-1 px-1">
    <>
      <NotificationGroup
        style={{
          zIndex: 9999999999999999,
          right: "55%",
          top: "12%",
          width: "50px",
          alignItems: "center",
          flexWrap: "wrap-reverse",
        }}
      >
        <Slide>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}

              // closable={true}

              // onClose={() =>
              //   setError(false)
              // }
            >
              Saved successfully.
            </Notification>
          )}
        </Slide>
      </NotificationGroup>
      <ExcelExport
        ref={_export}
        fileName={excelTitle + "_MyRelationships.xlsx"}
        data={result.data}
        group={initialGroup}
      >
        <ExcelExportColumnGroup
          title={
            excelTitle +
            "  Processing Date: " +
            localStorage.getItem("processingDate")
          }
          headerCellOptions={{
            textAlign: "left",
          }}
        >
          {stateColExport.map((w) => {
            return (
              <ExcelExportColumn
                field={w.field}
                title={w.title}
                hidden={!w.show}
                headerCellOptions={w.headerCellOptions}
                groupHeader={w.groupHeader}
                cellOptions={w.cellOptions}
                groupFooter={w.groupFooter}
                groupFooterCellOptions={w.groupFooterCellOptions}
              />
            );
          })}

          {/* <ExcelExportColumn
            field="cnsldtnNmFilter"
            hidden={true}
            groupHeader={CustomGroupHeader}
          />
          <ExcelExportColumn field="extrnlAcctId" title="Account No." />
          <ExcelExportColumn field="shrtNm" title="Account Name" />

          <ExcelExportColumn field="tpOwner" title="Owner" width={50} />
          <ExcelExportColumn field="tpAdmin" title="Admin" />
          <ExcelExportColumn field="tpInvOff" title="Inv. Off" />
          <ExcelExportColumn field="mjrAcctTypNm" title="Mjr. Acct. Type" />
          <ExcelExportColumn field="mnrAcctTypNm" title="Mnr. Acct. Type" />
          <ExcelExportColumn field="incptnDt" title="Inception Date" />
          <ExcelExportColumn field="invstmntObjctvNm" title="Inv. Objective" />

          <ExcelExportColumn
            field="mrktVlAmt"
            headerCellOptions={{ textAlign: "right" }}
            
            title="Market value ($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
            cellOptions={{ format: "#,##0.00" }}
          />
          <ExcelExportColumn
            field="ytdLngGainAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="YTD Long Gain Amt.($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="ytdLngLossAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="YTD Long Loss Amt.($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="ytdShrtGainAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="YTD Short Gain Amt.($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="ytdShrtLossAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="YTD Short Loss Amt.($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="ytdTotalLngAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="YTD Total Long-Term($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />

          <ExcelExportColumn
            field="ytdTotalShrtAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="YTD Total Short-Term($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="ytdTotalAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="YTD Total($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />

          <ExcelExportColumn
            field="p1CashBlncAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="Principal($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />

          <ExcelExportColumn
            field="p2CashBlncAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="Income($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="cashVlAmt"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="Sweep Balance($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="totalCash"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="Total Cash($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="totalUninvestedCash"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="Total Uninvested Cash($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          />
          <ExcelExportColumn
            field="totalLiqPriBal"
            headerCellOptions={{ textAlign: "right" }}
            cellOptions={{ format: "#,##0.00" }}
            title="Total Liquid Principal Balance($)"
            groupFooter={CustomGroupFooter} 
            groupFooterCellOptions={{textAlign: "right", format: "#,##0.00"}} 
          /> */}
        </ExcelExportColumnGroup>
      </ExcelExport>
      <Grid
        style={{
          height: windowHeight.current - 140,
          width: "auto",
          position: "absolute",
          border: "none",
        }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        onColumnReorder={onColumnReorder}
        onColumnResize={onColumnResize}
        expandField="expanded"
        onExpandChange={expandChange}
        cellRender={cellRender}
        sortable={true}
        resizable={true}
        pageable={true}
        //pageable={true}
        reorderable={true}
        pageSize={2000}
        groupable={{
          footer: "none",
          enabled: false,
        }}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onSelectionChange={onSelectionChange}
      >
        <GridNoRecords>
          <div style={{ color: "red", textAlign: "left" }}>
            No price exists for the selected date.
          </div>
        </GridNoRecords>
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                locked={column.locked}
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                footerCell={column.footerCell}
                headerClassName={column.headerClassName}
                {...column}
                // columnMenu={column.columnMenu}

                //cell={NumberCell}

                columnMenu={(props) => (
                  <>
                    {column.filter === "text" ? (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : column.filter === "numeric" ? (
                      <CustomColumnMenuNoGrp
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  </>
                )}
              />
            )
        )}
      </Grid>
      {themes === "dark" ? (
        <style>
          {`
      .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}
  .k-calendar   .k-focus
{

  background-color: #78e4ffa8 !important;

}
.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}

.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
      {/* </div>
      </div>
    </div> */}
    </>
  );
};

export default ConsolidationGrid;
