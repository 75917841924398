import React from 'react';
import * as ReactDOM from 'react-dom';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { process, orderBy  } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import {
  Grid,
  GridColumn as Column,
  GridGroupCell,
  GridToolbar,
} from '@progress/kendo-react-grid';

import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from '@progress/kendo-react-data-tools';
import Moment from 'react-moment';
import "hammerjs";
import { fixdincmfndmntlsDatavar } from './cstmRptMain';
import {ResponsiveContainer} from 'recharts';
const aggregates = [

];

const initialSort = [
  {
    field: "mtrtyYr",
    dir: "asc",
  },
];
const processWithGroups = (data, dataState) => {
  // 
  const groups = dataState.group;

  // if (groups) {
  //   groups.map((group) => (group.aggregates = aggregates));
  // }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const FixdIncmFundmntlsGrid = () => {
  //  
  const [data, setData] = useState(fixdincmfndmntlsDatavar);
  const [sort, setSort] = React.useState(initialSort);
  var tempToken = JSON.parse(localStorage.getItem('token'));  
  const [locked, setLocked] = React.useState(false);

  const columnLocked = () => {
    setLocked(!locked);
  };

// const columns = [
  let defaultColumnsUnchkd = [
    // {
    //   title: 'Account Number',
    //   field: 'accountNumber',
    //   minWidth: "auto",
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account Name',
    //   field: 'accountName',
    //   minWidth: 170,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: 'Maturity Year',
      field: 'mtrtyYr',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,      
      headerClassName: 'rightHeader',
    },
    {
      title: 'Description',
      field: 'astShrtNm',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Shares',
      field: 'shares',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader', 
    },
    {
      title: 'Market($)',
      field: 'market',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'YTM%',
      field: 'yldToMtrty',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Duration To Maturity',
      field: 'duration',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Current Yield%',
      field: 'yield',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Moody Rating',
      field: 'moodyRating',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'SP Rating',
      field: 'spRating',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    }
  ];

  // const columns1 = [
    let defaultColumnsChkd = [
    // {
    //   title: 'Account Number',
    //   field: 'accountNumber',
    //   minWidth: "auto",
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    // {
    //   title: 'Account Name',
    //   field: 'accountName',
    //   minWidth: 170,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: 'Maturity Year',
      field: 'mtrtyYr',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: true,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Description',
      field: 'astShrtNm',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Shares',
      field: 'shares',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',  
    },
    {
      title: 'Market($)',
      field: 'market',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'YTM%',
      field: 'yldToMtrty',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'YTW%',
      field: 'yldCalPut',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Duration To Maturity',
      field: 'duration',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Duration To Call/Put',
      field: 'calPutDuration',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',
    },
    {
      title: 'Current Yield%',
      field: 'yield',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: 'rightHeader',    
    },
    {
      title: 'Moody Rating',
      field: 'moodyRating',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'SP Rating',
      field: 'spRating',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    }
  ];

  const GridColumnsChkd =  defaultColumnsChkd;
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 10,
    skip: 0,
    // sort: [{ field: 'mtrtyYr', dir: 'asc' }],
    group: [{ field: 'mtrtyYr' }]
  });
 

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );


  const [dataState, setDataState] = React.useState(initialState.dataState);
  // const [stateColumns, setStateColumns] = React.useState(GridColumns);
  const [stateColumnsChkd, setStateColumnsChkd] = React.useState(GridColumnsChkd);
  const [currentColumns, setCurrentColumns] = React.useState('');
  // const [chartData, setSelectedData] = React.useState(orderBy(data, sort));
  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });
  const dataStateChange = (event) => {
    
    let updatedState = createDataState(event.dataState);
    
    setResult(processWithGroups(data, updatedState.dataState));

    setDataState(updatedState.dataState);
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumnsChkd(columnsState);
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  const cellRender = (tdElement, cellProps) => {    
    
    
    if (cellProps.rowType === "data")
    {
    let cpnRate="", matrtyDate="";

    if(cellProps.field==="shares")
      {
        return (
         
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
      );
      }
    
    if(cellProps.field==="market")
      {
        return (
          
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>          
      );
      }

    if(cellProps.field==="yldToMtrty")
      {
        return (          
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem["yldToMtrty"], "##,#.00")}
          </td>          
      );
      }
      if(cellProps.field==="yldCalPut")
      {
        return (         
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem["yldCalPut"], "##,#.00")}
          </td>         
      );
      }
      if(cellProps.field==="duration")
      {
        return (          
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem["duration"], "##,#.00")}
          </td>
      );
      }
      if(cellProps.field==="calPutDuration")
      {
        return (          
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem["calPutDuration"], "##,#.00")}
          </td>
      );
      }
      if (cellProps.field === "yield") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }  
    if(cellProps.field==="astShrtNm")
    {
      cpnRate=cellProps.dataItem["couponRate"];
      matrtyDate=cellProps.dataItem["maturityDt"];
      return (
        <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {cellProps.dataItem[cellProps.field]}&nbsp;{ formatNumber(cpnRate, "##,#.00")}%&nbsp;
            <Moment format="MM/DD/YYYY">
            {matrtyDate}
          </Moment>
          &nbsp;
        </td>
    );
    }
  }
    return tdElement;
  };


const [show, setShow] = React.useState(false);
const grdFim=(
  <Grid
  id='tileGrid'
              style={{ height: '500px' }}
              // data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              //onExpandChange={expandChange}
              // sortable={true}

              data={orderBy(result.data, sort)}
              sortable={true}
              sort={sort}
              //onSortChange={(e) => {
              //  setSort(e.sort);
              //}}
              className='page-break'
              resizable={true}
              //pageable={true}
              //pageSize={20}
              groupable={{
                footer: 'visible',
                enabled: false,
              }}
              cellRender={cellRender}  
            >             
              {stateColumnsChkd.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      headerClassName={column.headerClassName}
                      footerCell={column.footerCell}
                      // cell={NumberCell}
                      columnMenu={(props) => (
                        <CustomColumnMenuNoGrp
                          {...props}
                          columns={ stateColumnsChkd }
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                    />
                  )
              )}
            </Grid>
 );

  return (
    <div>
       <ResponsiveContainer> 
       <div><h5 className='text-fitek p-1'>Fixed Income Fundamentals</h5>
      <div className="card-body">
        <div className="mx-1 px-1 my-1 py-1"> 
            {grdFim}
        </div>        
      </div>
      </div>
      </ResponsiveContainer>
    </div>
  );
};

export default FixdIncmFundmntlsGrid
