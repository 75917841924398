import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './loading';
import TbGrid from './tbGrid';
import Modal from 'react-bootstrap/Modal';
import { FcExpired } from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
const TbRpt = ({menuStateData}) => {
    
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState("");
    const [TbData, populateTbData] = useState([]);
    const [flag,setFlag]=useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      
        const fetchData = async () => {
             setLoading(true);
            try {
                
                let asOfId = JSON.parse(localStorage.getItem('userId'));
                
               
                GetTrustBriefData();
              
                
            } catch (error) {
                console.error(error.message);
            }
    
        }
        fetchData();
    }, [])

    const refreshToken = async () => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        await axios.post('/token/Refresh',
          postData
    
        )
          .then((response) => {
    
            tempToken = response.data;
            localStorage.setItem('token', JSON.stringify(response.data));
    
            GetTrustBriefData();
    
          })
          .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
              setSession("Session Expired");
            }
    
            console.log("my error is " + error);
          })
    
      }

      const GetTrustBriefData= async () => {
        
        let token = tempToken;
        let CnsldtnId = localStorage.getItem('pConsId');
        const postData = {CnsldtnId};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        await axios.post('/AdvTrustBrief',
          postData,
          config
        )
          .then(response => {
            
            console.log(response);
            setLoading(false);
            setFlag(true);
            const rowData = response.data;
            populateTbData(rowData);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              refreshToken();
    
            }
            //return error;
          });

      }
      const signOut = () => {

        navigate("/");
        let token = JSON.parse(localStorage.getItem('token'));
        const postData = {};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        axios.post('/token/revoke',
          postData,
          config
        )
          .then((response) => {
            // 
            // localStorage.setItem('token', '');
            //console.log(response);
            // navigate("/");
    
            // if (response.statusText === '') {
    
            // }
    
          })
          .catch((error) => {
            // 
            console.log("my error is " + error);
          })
    
        // let tokenNew={token:''};
        // localStorage.setItem('token',JSON.stringify(tokenNew));
        // localStorage.setItem("AcctSelected",null);
        localStorage.removeItem('token');
        localStorage.clear();
        //firebaseApp.auth.signOut();
    
      }
      if (loading) {
        return (
          <>
            {session === "Session Expired" ?
    
              <Modal show={true} fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{ outlineColor: "black", borderBlockColor: "black", backgroundColor: "#0099ff", width: "50px" }} onClick={signOut}>OK</a></div></Modal.Body></Modal>
              :
              <div>
                {/* <Header></Header> */}
                <Loading />
              </div>}</>
        )
      }
  return (
    <div>
      {flag?<TbGrid data={TbData} menuStateData={menuStateData}></TbGrid>:<></>}
    </div>
  )
}

export default TbRpt
