import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useState, useEffect, useRef } from 'react';
import { ColumnMenu } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ResponsiveContainer } from "recharts";
import {perfRORDatavar} from './cstmRptMain';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
// const aggregates = [
//     {
//       field: "market",
//       aggregate: "sum",
//     }
  
//   ];

  const AcctperfRORWidget= () => {
    
    const gridRef = useRef();
    //const [portfolioHoldingsdata, setPortfolioHoldingsdata] = useState(JSON.parse(global.localStorage.getItem("portfoliHoldingData")));
    //console.log({cashflowDatavar});
    let defaultColumns = [
            {
              title: 'Account Number',
              field: 'extrnlAcctId',
              minWidth: "auto",
              show: true,
              filter: 'text',
              locked: false,
            },
            {
              title: 'Account Name',
              field: 'shrtNm',
              minWidth: "auto",
              show: true,
              filter: 'text',
              locked: false,
            },
            {
              title: 'Investment Objective',
              field: 'iobNm',
              minWidth: "auto",
              show: true,
              filter: 'text',
              locked: false,
        
            },
            {
              title: 'Month to Date',
              field: 'monthToDate',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
            },
            {
              title: 'Quarter to Date',
              field: 'quarterToDate',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
            },
            {
              title: 'Year to Date',
              field: 'yearToDate',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
            },
            {
              title: 'Last 1 year',
              field: 'oneYear',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
            },
            {
              title: 'Last 3 years',
              field: 'threeYear',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
            },
            {
              title: 'Last 5 years',
              field: 'fiveYear',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
            },
            {
              title: 'Last 10 years',
              field: 'tenYear',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
            },
            {
              title: 'Since inception',
              field: 'inception',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
            },
        
        
          ];
    const [cashflowdata, setcashflowdata] = useState(perfRORDatavar);
    const [stateColumns, setStateColumns] = useState(defaultColumns);
    const createDataState = (dataState) => {
        return {
          result: process(cashflowdata.slice(0), dataState),
          dataState: dataState,
        };
      };
      let initialState = createDataState({
        take: 20,
        skip: 0,
      });
      const [result, setResult] = React.useState(initialState.result);
      const [dataState, setDataState] = React.useState(initialState.dataState);
      const [sort, setSort] = React.useState([]);
      const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
      };
      const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
      };
    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const NumberCellSixDecimal = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.000000")}
            </td>
        )
    }
    const totalSum = (props) => {
        const field = props.field || "";
        const total = cashflowdata.reduce((acc, current) => acc + current[field], 0).toFixed(2);;
        return (
          <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {formatNumber(total, '##,#.00')}
          </td>
        );
      };

      const cellRender = (tdElement, cellProps) => {
        if (cellProps.rowType === "data") {
          if (cellProps.field === "monthToDate") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "quarterToDate") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "yearToDate") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "oneYear") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "threeYear") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "fiveYear") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "tenYear") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "inception") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
        }
        return tdElement;
      }
      
    return(
        
                <ResponsiveContainer className='page-break'>   
                <div><h5 className='text-fitek p-1'>Performance ROR data
</h5>   
            <Grid
              style={{ width: '100%', height: "auto"}}
              id='tileGrid'
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              //onExpandChange={expandChange}
              cellRender={cellRender}
              sortable={true}
              scrollable={true}
              resizable={true}
              reorderable={true}
              
              //pageable={true}
              //pageSize={10}
              groupable={{
                footer: 'visible',
                enabled:false
              }}
              //
              ref={gridRef.current}
              //onColumnReorder={onColumnReorder}
              //onColumnResize={onColumnResize}
            //
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      //width={setWidth(column.minWidth)}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      headerClassName={column.headerClassName}
                      {...column}
                      columnMenu={(props) => (
                        <CustomColumnMenuNoGrp
                          {...props}
                          columns={stateColumns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                    />
                  )
              )}
            </Grid>
          </div>
          </ResponsiveContainer>          
      
                    

                
    )

}
  export default AcctperfRORWidget

