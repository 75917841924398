import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {assetVsModelFinalDatavar} from './cstmRptMain';

import { ResponsiveContainer } from "recharts";


const AssetvsModelDataWidget = () => {
  
  const [astModelData, setastModelData] = useState(assetVsModelFinalDatavar);

  const menuWithExcelCheckOnly = (props) => { 
    const props1 = {...props};
    return (
      <div>
        <ColumnMenuCheckboxFilter
          {...props1}
          data={assetVsModelFinalDatavar}
        />
      </div>)
  }

  const createDataState = (dataState) => {
    return {
      result: process(astModelData.slice(0), dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 20,
    skip: 0,
  });
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [sort, setSort] = React.useState([]);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

const NumberCell = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
    )
}
const NumberCellSixDecimal = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.000000")}
        </td>
    )
}

const totalAcVMdl = (props) => {
  const field = props.field || "";
  const total = astModelData.reduce((acc, current) => acc + current[field], 0);
  return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
          {formatNumber(total, "##,#.00")}
      </td>
  );
};
  
  return (
    <div><h5 className='text-fitek p-1'>Asset Versus Model Data</h5>
    <ResponsiveContainer>
    <Grid style={{width:"100%" ,height: "300px" }}
    
                                    data={orderBy(astModelData,sort)}
                                    onDataStateChange={dataStateChange}
                                    {...dataState}
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                 setSort(e.sort);
                                }}
                                
                                >

                                    <Column field="descption" menu={true} title="Description" width="auto" columnMenu={menuWithExcelCheckOnly}/>
                                    <Column field="prtfolio" menu={true} title="Portfolio($)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} headerClassName= 'rightHeader' columnMenu={ColumnMenu} />
                                    <Column field="prtfolioWeigh" menu={true} title="Portfolio(%)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} headerClassName= 'rightHeader' columnMenu={ColumnMenu}/>

                                    <Column field="mdl" menu={true} title="Model($)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} headerClassName= 'rightHeader' columnMenu={ColumnMenu}/>

                                    <Column field="mdlWegh" menu={true} title="Model(%)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell}  headerClassName= 'rightHeader' columnMenu={ColumnMenu}/>
                                    <Column field="varitoMdl_Amt" menu={true} title="Variance($)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell} headerClassName= 'rightHeader'  columnMenu={ColumnMenu}/>
                                    <Column field="varitoMdl" menu={true} title="Variance(%)" width="auto" footerCell={totalAcVMdl}  cell={NumberCell}  headerClassName= 'rightHeader' columnMenu={ColumnMenu}/>
                                </Grid>
                                </ResponsiveContainer>
     </div>
  )
}





export default AssetvsModelDataWidget