import { createSlice } from "@reduxjs/toolkit"

const tokenSlice = createSlice({
    name: 'token',
    initialState: { user:null },
    reducers: {
        login: (state, action) => {
            
            state.user = action.payload
            
        },
        logOut: (state) => {
            state.user = null
           
        }
    },
})

export const { login, logOut } = tokenSlice.actions

export default tokenSlice.reducer

export const selectUser = (state) => state.auth.user
