import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useState, useEffect, useRef } from 'react';
import { ColumnMenu } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ResponsiveContainer } from "recharts";
import {cashflowDatavar} from './cstmRptMain';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
// const aggregates = [
//     {
//       field: "market",
//       aggregate: "sum",
//     }
  
//   ];

  const CashflowWidget= () => {
    
    const gridRef = useRef();
    //const [portfolioHoldingsdata, setPortfolioHoldingsdata] = useState(JSON.parse(global.localStorage.getItem("portfoliHoldingData")));
    console.log(cashflowDatavar);
    let defaultColumns = [
        // {
        //     title: 'Account Number',
        //     field: 'extrnlAcctId',
        //     minWidth: "auto",
        //     show: true,
        //     filter: 'text',
        //     locked: true,
        //   },
        // {
        //   title: 'Account Name',
        //   field: 'shrtNm',
        //   minWidth: "auto",
        //   show: true,
        //   filter: 'text',
        //   locked: true,
        // },
        {
          title: 'Port',
          field: 'portId',
          minWidth: "auto",
          show: true,
          filter: 'text',
          locked: false,
        },
        {
          title: 'T0',
          field: 't0',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName:"rightHeader"
        },
        {
          title: 'T1',
          field: 't1',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName:"rightHeader"
        },
        {
          title: 'T2',
          field: 't2',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName:"rightHeader"
        },
        {
          title: 'T2+',
          field: 't3',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName:"rightHeader"
        },
      ];
      const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };
    const [cashflowdata, setcashflowdata] = useState(cashflowDatavar);
    const [stateColumns, setStateColumns] = useState(defaultColumns);
    const createDataState = (dataState) => {
        return {
          result: process(cashflowdata.slice(0), dataState),
          dataState: dataState,
        };
      };
      let initialState = createDataState({
        take: 20,
        skip: 0,
      });
      const [result, setResult] = React.useState(initialState.result);
      const [dataState, setDataState] = React.useState(initialState.dataState);
      const [sort, setSort] = React.useState([]);
      const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
      };

    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const NumberCellSixDecimal = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.000000")}
            </td>
        )
    }
    const totalSum = (props) => {
        const field = props.field || "";
        const total = cashflowdata.reduce((acc, current) => acc + current[field], 0).toFixed(2);;
        return (
          <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {formatNumber(total, '##,#.00')}
          </td>
        );
      };
      const getCells = (columns, cellProps) => {
        let cells = [];
        columns.forEach((column) => {
          if (column.aggregate) {
            cells.push(
              <td style={{ textAlign: "right" }}>
                {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
              </td>
            );
          } else {
            cells.push(<td>&nbsp;</td>);
          }
        });
        return cells;
      };
      const cellRender = (tdElement, cellProps) => {
        if (
          cellProps.rowType === 'groupHeader' &&
          tdElement &&
          tdElement.props.role != 'presentation'
        ) {
          
          const columns = defaultColumns;
          return (
            <>
              <td
                {...tdElement.props}
                colSpan={tdElement.props.colSpan - columns.length}
              ></td>
              {getCells(columns, cellProps)}
            </>
          );
        }
        if (cellProps.rowType === 'groupFooter') {
    
          if (cellProps.field === 'totMarket') {
            return (
              <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
                {cellProps.dataItem.aggregates.totMarket.sum}
              </td>
            );
          }
    
        }
        if (cellProps.rowType === "data") {
    
          if (cellProps.field === "t0") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "t1") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "t2") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "t3") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "gainLoss") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "income") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "yield") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "p1CashBlncAmt") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "p2P3CashBlncAmt") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "unExecCashAmt") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "tradeCash") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "excldCashAmt") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "equityPercent") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
        }
        return tdElement;
      };
    return(
        
                <ResponsiveContainer >   
                  <div>
                  <h5 className='text-fitek p-1'>Cash Flow</h5>
            <Grid
            id='tileGrid'
              style={{ width: '100%', height: "auto"}}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              //onExpandChange={expandChange}
              //cellRender={cellRender}
              sortable={true}
              scrollable={true}
              resizable={true}
              reorderable={true}
              cellRender={cellRender}
              //pageable={true}
              //pageSize={10}
              groupable={{
                footer: 'visible',
                enabled:false
              }}
              //
              ref={gridRef.current}
              //onColumnReorder={onColumnReorder}
              //onColumnResize={onColumnResize}
            //
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      //width={setWidth(column.minWidth)}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      headerClassName={column.headerClassName}
                      {...column}
                      columnMenu={(props) => (
                        <CustomColumnMenuNoGrp
                          {...props}
                          columns={stateColumns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                    />
                  )
              )}
            </Grid>
            </div>   
          </ResponsiveContainer>          
      
                    

                
    )

}
  export default CashflowWidget

