import React from 'react'
import { useState, useEffect, useRef } from 'react';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {ResponsiveContainer} from 'recharts';
import {acctProfFinalDatavar} from './cstmRptMain';
// const aggregates = [
const AcctHoldingWidget=()=>{
    const [chartType, setChartType] = useState("pie");
    const [data, setData] = useState(acctProfFinalDatavar);
    

    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.marketPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.marketPercent.toFixed(2)}%`;
    };

    return(
        <div><h5 className='text-fitek p-1'>Account Profile</h5>
    <ResponsiveContainer >

        
    {data.length===0?<>No Data Found.</>:
            <Chart>
                {/* <ChartTitle text="Major Asset Chart" /> */}
                <ChartLegend position="bottom" />

                <ChartSeries>
                    <ChartSeriesItem
                        type={chartType}
                        data={data}
                        colorField='chartColorCOde'
                        field="marketPercent"
                        categoryField="groupName"
                        autoFit={true}
                        labels={{
                            visible: true,
                            content: labelContent1,
                        }}
                    />
                </ChartSeries>
            </Chart>}
            

      </ResponsiveContainer></div>
);
};
export default AcctHoldingWidget