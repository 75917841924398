import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './loading';
import PersistentDrawerLeftNew from './drawerLeftNew'
import 'hammerjs';
import { Tabs } from 'antd';




var dashboardData;
const Dashboard = () => {

  const [session,setSession]=useState("");
  const [loading, setLoading] = useState(true);
  const [ownerAcct, setOwnerAcct] = useState("");
  const [consAcct, setConsAcct] = useState("");
  const [acctList, setAcctList] = useState("");
  const [consList, setConsList] = useState("");
  const [flag, setFlag] = useState(false);
  var tempToken = JSON.parse(localStorage.getItem('token'));
  if(!localStorage.getItem('pConsId'))
  localStorage.setItem('pConsId', 0);
 // if(localStorage.getItem('activeTab')!="8")
  localStorage.setItem("activeTab","1");
  //localStorage.setItem('SelAcctId', 0);
  return (
    <div>
      
     

<PersistentDrawerLeftNew/>



      
    </div>
  )
}
export {dashboardData}
export default Dashboard
