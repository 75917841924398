import React from 'react'
import { Circles,Oval,BallTriangle,RotatingLines,RevolvingDot } from 'react-loader-spinner';

import logobar from '../Images/loadingbar.gif';

const Logging = () => {
    return (

        
        <div className="loadingDiv d-flex justify-content-center align-items-center " style={{backgroundColor:"none"}}>
            
             <div>
      {/* <img  src={logobar}></img> */}
      <RotatingLines
  strokeColor="#006fba"
  strokeWidth="7"
  animationDuration="1"
  width="30"
  visible={true}
  
/>
    </div>
        </div>
    )
}

export default Logging