import React from 'react'
import { useState, useEffect, useRef } from 'react';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {ResponsiveContainer} from 'recharts';

import {assetVsModelFinalDatavar} from './cstmRptMain';

const AssetVsModelChartWidget = () => {

    const [chartType, setChartType] = useState("column");
    const [astModelChartdata, setastModelChartdata] = useState(assetVsModelFinalDatavar);
    const chartDefaultV4Colors = [
        "#235ee7",
        "#dce4ea",
        "#4ac9c9",
        "#d3dffb",
        "#7c9ff2",
        "#f3b021",
        "#f8d0c0",
    ];
    const labelContent = (e) => `${e.value.toFixed(2)}%`;
    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.marketPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${props.dataItem.marketPercent.toFixed(2)}%`;
    };
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}%`;
    return (
        <div><h5 className='text-fitek p-1'>Asset Versus Model Chart</h5>
            <ResponsiveContainer >
                {astModelChartdata.length===0?<>No Data Found.</>:
            <Chart  zoomable={false}>
            <ChartLegend position="bottom" />
                <ChartValueAxis>
                    <ChartValueAxisItem
                         // title={{
                        //     text: "Percentage",
                        // }}
                        min={0}
                        max={110}
                    />
                </ChartValueAxis>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        labels={{
                            visible: true,
                            rotation: "10",
                            format: "d",
                            }}

                            //  categories={categoryAxis} 
                    />
                </ChartCategoryAxis>
                <ChartSeriesDefaults
                    type="column"
                    labels={{
                    visible: true,
                    format: "n2",
                    }}
                />
                <ChartTooltip render={defaultTooltipRender} />
                <ChartSeries>
                    <ChartSeriesItem
                        data={astModelChartdata}
                        //colorField='chartColorCOde'
                        type='column'
                        field='prtfolioWeigh'
                        categoryField='descption'
                        name='Portfolio'
                        // aggregate='sum'
                        labels={{
                        visible: true,
                        content: labelContent,
                        }}

                    />

                    <ChartSeriesItem
                        data={astModelChartdata}
                       // colorField='chartColorCOde'
                        type='column'
                        field='mdlWegh'
                        categoryField='descption'
                        name='Model'
                        // aggregate='sum'
                        labels={{
                            visible: true,
                            content: labelContent,
                        }}

                    />

                </ChartSeries>

            </Chart>}
            </ResponsiveContainer>
    </div>
     
  )
}

export default AssetVsModelChartWidget

