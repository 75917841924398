import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import {
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { fimMaturityDeatils } from './cstmRptMain';
import {ResponsiveContainer} from 'recharts';

const initialGroup = [
  {
    // field: "accountNumber",
  }
];

const initialDataState = {
  skip: 0,
  take: 10,
};

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;
  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const FimMtrtyDtlWidget = () => {
  
  console.log(fimMaturityDeatils);
  const RightNameHeader = (props) => {
    return (
      <a className="k-link" style={{
        float: "right",
      }} onClick={props.onClick}>
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={{
            // color: "#53d2fa",
          }}
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };

  const columns = [   
    {
      title: 'Description',
      field: 'mtrtyYrDesc',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
    },
    {
      title: 'Par Value',
      field: 'shares',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      // footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: 'Market Value($)',
      field: 'market',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      // footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: 'Income($)',
      field: 'income',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      // footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: 'Yield%',
      field: 'yield',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      // footerCell: avgYield,
      headerCell: RightNameHeader,
    },
    {
      title: 'Percent',
      field: 'marketPercent',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerCell: RightNameHeader,
    }
  ];
  
  const createDataState = (dataState) => {
    return {
      result: process(fimMaturityDeatils, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 20,
    skip: 0,
    sort: [{ field: 'mtrtyYrDesc', dir: 'asc' }],
  });
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [resultState, setResultState] = React.useState(
    processWithGroups(fimMaturityDeatils, initialState.dataState)
  );
  const [stateColumns, setStateColumns] = React.useState(columns);


  let pageSize = 10;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });
 

  const onDataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);

    setResultState(processWithGroups(fimMaturityDeatils, updatedState.dataState));

    setDataState(updatedState.dataState);
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const cellRender = (tdElement, cellProps) => {

    if (cellProps.rowType === "data") {

      if (cellProps.field === "shares") {
        return (

          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "market") {
        return (

          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "income") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketPercent") {
        // 
        let mb = 100;
        let mc = cellProps.dataItem[cellProps.field];
        return (

          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(mc * mb, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yield") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }

    return tdElement;
  };

  const pageChange = (event) => {
    setPage(event.page);
  };

  const onExpandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResultState({ ...resultState });
  };  
  const grid = (
    <Grid
    id='tileGrid'
      style={{ height: "auto" }}
      data={resultState}
      {...dataState}
      onDataStateChange={onDataStateChange}
      expandField="expanded"
      //onExpandChange={onExpandChange}
      cellRender={cellRender}
      //sortable={true}
      resizable={true}
      //pageable={true}
      //reorderable={true}

      //pageSize={10}

      groupable={{
        footer: 'visible',
        enabled: false
      }}
    >
      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <Column
              width={setWidth(column.minWidth)}
              locked={column.locked}
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              footerCell={column.footerCell}
              headerClassName={column.headerClassName}
              {...column}
              //cell={NumberCell}
              columnMenu={(props) => (
                <CustomColumnMenuNoGrp
                  {...props}
                  columns={stateColumns}
                />
              )}
            />
          )
      )}
    </Grid>
  );

  return (

    <ResponsiveContainer >
      {/* <div className="card-body">
        <div className="mx-1 px-1 my-1 py-1"> */}
<div>
<h5 className='text-fitek p-1'>Maturity Details</h5>
          {grid}
          </div>
        {/* </div>
      </div> */}
      </ResponsiveContainer>
  )
}

export default FimMtrtyDtlWidget
